import React, { useEffect, useState } from 'react';
import styles from './PartnerAssistance.module.css';
import { useTranslation } from 'react-i18next';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import classnames from 'classnames';
import { getBookingAdminToolConfig } from '../../apiCall';

interface IPartnerAssistance {
  showMobileReservationInputFormFlags?: boolean;
}

const PartnerAssistance = ({ showMobileReservationInputFormFlags }: IPartnerAssistance) => {
  const partnerAssistanceSection = classnames(styles.partnerCustomerService, {
    [styles.partnerAssistanceSection]: useCheckMobileScreen() && showMobileReservationInputFormFlags
  });
  const { t } = useTranslation();
  const [phNo, setPhNo] = useState('');

  useEffect(() => {
    const getAdminConfig = async () => {
      const res = await getBookingAdminToolConfig();
      setPhNo(res.settings.phNos[0]);
    };
    getAdminConfig();
  }, []);

  return (
    <>
      <section aria-label="Get help by phone">
        <div className={partnerAssistanceSection} data-testid="bookingCustomerServiceDiv">
          <p className={styles.partnerAssistanceText}>
            {t('reservationBookingAssistance_1')}{' '}
            <strong>{t('reservationBookingAssistance_2')}</strong>{' '}
            {t('reservationBookingAssistance_3')}{' '}
            <strong>{t('reservationBookingAssistance_4')}</strong>
            {t('reservationBookingAssistance_5')}
          </p>

          <div className={styles.partnerCustomerNumber}>
            <p>{phNo}</p>
          </div>
          <div className={styles.partnerLanguageSupport}>
            <p>{t('partner_assistance_message')}</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default PartnerAssistance;
