import React, { useState, useEffect } from 'react';
import bookingLogo from '../../assets/images/Booking-logo.png';
import styles from './PartnerHeader.module.css';
import { useTranslation } from 'react-i18next';
import {
  getDefaultLanguageHandler,
  Languages,
  LanguageSelector,
  SelectedLanguage
} from 'abu-common-components';
import SingleInputReservationForm from '../singleReservationForm/SingleInputReservationForm';
import MagnifyingGlassFill from '../../assets/images/MagnifyingGlassFill.png';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { LogoutIcon } from '@bookingcom/bui-assets-react/streamline';
import { Icon } from '@bookingcom/bui-react';
import { Auth } from 'aws-amplify';
import { getCurrentPartnerSession } from '../../utils/utils';
import { getPartnerPropertyList, postPartnerLogout } from '../../apiCall';
import { trackPromise } from 'react-promise-tracker';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { expiryTimeLimit } from '../../utils/constant';

interface IPartnerHeader {
  showMobileReservationInputFlag?: boolean;
  showMobileReservationInputHandler?: (flag: boolean) => void;
  propertyCount?: string;
  propertyHotelId?: string;
  propertyHotelName?: string;
}

const PartnerHeader = ({
  showMobileReservationInputFlag,
  showMobileReservationInputHandler,
  propertyCount,
  propertyHotelId,
  propertyHotelName
}: IPartnerHeader) => {
  const { i18n } = useTranslation();
  const { hotelId } = useParams();
  const location = useLocation();
  const checkMobileScreenFlag = useCheckMobileScreen();
  const navigate = useNavigate();
  const defaultLanguage = getDefaultLanguageHandler(i18n, Languages);
  const [language, setLanguage] = useState(defaultLanguage);
  const [openLanguageModal, setOpenLanguageModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(propertyHotelId || hotelId);
  const [partnerPropertyList, setPartnerPropertyList] = useState(Object);

  const updateExpireTime = () => {
    const expireTime = Date.now() + expiryTimeLimit;
    localStorage.setItem('expireTime', expireTime.toString());
  };

  const checkForInactivity = async () => {
    const expireTime = Number(localStorage.getItem('expireTime'));
    if (expireTime < Date.now()) {
      await trackPromise(
        postPartnerLogout().then((response) => {
          if (response.statusCode === 202) {
            Auth.signOut().then();
          }
        })
      );
      navigate('/partner/inActivity', { state: { isUserInActive: true } });
    }
  };
  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
    const selectedHotelName = partnerPropertyList.properties.hotels.find(
      (hotel: { hotel_id: string; hotelName: string; callback_number: string }) =>
        hotel.hotel_id === event.target.value
    );
    navigate(`/partner/hotel/reservation/${event.target.value}/${selectedHotelName.hotelName}`);
  };

  useEffect(() => {
    updateExpireTime();
    window.addEventListener('click', updateExpireTime);
    window.addEventListener('mousemove', updateExpireTime);

    return () => {
      window.removeEventListener('click', updateExpireTime);
      window.removeEventListener('mousemove', updateExpireTime);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    (async () => {
      const userSession = await getCurrentPartnerSession();
      if (typeof userSession !== 'string' && userSession?.isUserValid !== true) {
        location.pathname !== '/partner/inActivity' && navigate('/', { replace: true });
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await trackPromise(
        getPartnerPropertyList().then((response) => {
          if (response.responseCode === 200) {
            setPartnerPropertyList(response);
          }
        })
      );
    })();
  }, []);
  async function signOutHandler() {
    await trackPromise(
      postPartnerLogout().then((response) => {
        if (response.statusCode === 202) {
          Auth.signOut().then();
        }
      })
    );
    navigate('/partner/inActivity', { replace: true, state: { isUserInActive: false } });
  }
  const hotelDropDown = (dropDownClassName: string | undefined) => {
    return (
      <div className={dropDownClassName}>
        <Box sx={{ minWidth: 60 }} data-testid="propertyListTestId">
          <FormControl style={{ width: '100%' }}>
            <Select
              sx={{
                color: 'white',
                '.MuiSvgIcon-root': {
                  fill: 'white'
                },
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  border: 0
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 0
                }
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              data-testid="demoSimpleSelectTestId"
              value={selectedOption}
              onChange={handleChange}>
              {partnerPropertyList?.properties?.hotels?.map((option: any, index: string) => (
                <MenuItem
                  className={styles.mobileDropDownMenuItem}
                  key={index}
                  value={option.hotel_id}>
                  {option.hotelName} - {option.hotel_id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>
    );
  };

  const renderSingleReservationForm = () => {
    if (location.pathname !== '/partner/inActivity') {
      if (
        partnerPropertyList?.properties?.hotels.length > 1 &&
        location.pathname === '/partner/property'
      ) {
        return <></>;
      } else {
        return (
          <SingleInputReservationForm
            hotelPropertyCount={propertyCount}
            propertyHotelId={propertyHotelId}
            propertyHotelName={propertyHotelName}
          />
        );
      }
    } else {
      return <></>;
    }
  };

  return (
    <>
      {openLanguageModal && (
        <div role="dialog">
          <LanguageSelector
            availableLanguages={Languages}
            changeLanguage={setLanguage}
            language={language}
            toggleLanguageModal={setOpenLanguageModal}
          />
        </div>
      )}
      <div className={styles.navbarContainer} data-testid={'headerDivTestId'}>
        <div className={styles.leftContainer}>
          <div
            className={styles.logoContainer}
            onClick={() => {
              navigate('/partner/property');
              showMobileReservationInputHandler &&
                showMobileReservationInputHandler(!showMobileReservationInputFlag);
            }}>
            <img className={styles.logo} src={bookingLogo} alt={'Booking logo'} />
          </div>
          {partnerPropertyList?.properties?.hotels.length > 1 &&
            location.pathname !== '/partner/inActivity' &&
            location.pathname !== '/partner/property' &&
            hotelDropDown(styles.dropDown)}
        </div>
        <div className={styles.navbarRightContainer}>
          {renderSingleReservationForm()}
          {location.pathname !== '/partner/inActivity' && (
            <img
              className={styles.headerSearchIconImg}
              src={MagnifyingGlassFill}
              alt={'MagnifyingGlassFill icon for reservation id search'}
              onClick={() =>
                showMobileReservationInputHandler && showMobileReservationInputHandler(true)
              }
            />
          )}
          <SelectedLanguage
            selectLanguageClassName={styles.selectLanguageContainer}
            language={language}
            changeLanguageModal={setOpenLanguageModal}
            hoverStyle={{
              onHoverLanguage: 'rgba(0,0,0,0.1)',
              backgroundColorLanguage: '#003580'
            }}
          />
          {location.pathname !== '/partner/inActivity' && (
            <div
              onClick={signOutHandler}
              className={styles.partnerLogoutButton}
              data-testid={'logOutDivTestId'}>
              <Icon
                size={'large'}
                color={'white'}
                svg={LogoutIcon}
                ariaLabel={'partner logout button'}
              />
            </div>
          )}
        </div>
      </div>
      {checkMobileScreenFlag &&
        partnerPropertyList?.properties?.hotels.length > 1 &&
        hotelDropDown(styles.mobileDropDown)}
    </>
  );
};

export default PartnerHeader;
