import React from 'react';
import PartnerHeader from '../../components/partnerHeader/PartnerHeader';
import AppInfo from '../../components/appInfo/AppInfo';
import styles from './PartnerInActivityPage.module.css';
import Card from '../../components/UI/card/Card';
import Button from '../../components/UI/button/Button';
import PartnerAssistance from '../../components/partnerAssistance/PartnerAssistance';
import PartnerFooter from '../../components/partnerFooter/PartnerFooter';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
const PartnerInActivityPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const checkMobileScreenFlag = useCheckMobileScreen();

  return (
    <>
      <Helmet>
        <title>{`Logged out | Booking.com`}</title>
      </Helmet>
      <header>
        <PartnerHeader />
      </header>
      <AppInfo />
      <main>
        <section className={styles.partnerInActivitySection}>
          <Card className={styles.partnerInActivityContainer}>
            {location.state.isUserInActive && (
              <div className={styles.partnerInActivityContent}>
                <p>{t('partnerLoggedOutInActivity')}</p>
                <br />
                <Button
                  type={'button'}
                  className={styles.secureLoginLinkButton}
                  onClick={() => navigate('/', { replace: true })}>
                  {t('RequestSecureLoginLink')}
                </Button>
              </div>
            )}
            {!location.state.isUserInActive && (
              <div className={styles.partnerLogOutContent}>
                <p>{t('You_have_been_logged_out')}</p>
              </div>
            )}
          </Card>
          <br />
          <PartnerAssistance />
        </section>
      </main>
      {!checkMobileScreenFlag && (
        <footer className={styles.partnerInActivityFooterContainer}>
          <PartnerFooter />
        </footer>
      )}
    </>
  );
};

export default PartnerInActivityPage;
