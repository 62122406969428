import React from 'react';
import styles from './SingleInputReservationForm.module.css';
import Input from '../UI/input/Input';
import Button from '../UI/button/Button';
import { Formik } from 'formik';
import MagnifyingGlass from '../../assets/images/MagnifyingGlass.png';
import { useNavigate, useLocation, useParams, createSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
interface IOnSubmitInput {
  setSubmitting: (flag: boolean) => void;
}
interface IReservationIdFormValues {
  reservationId: string;
}

interface ISingleInputReservationForm {
  hotelPropertyCount?: string;
  propertyHotelId?: string;
  propertyHotelName?: string;
}

const SingleInputReservationForm = ({
  hotelPropertyCount,
  propertyHotelId,
  propertyHotelName
}: ISingleInputReservationForm) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { hotelId, hotelName } = useParams();
  const reservationIdSubmitHandler = (
    values: IReservationIdFormValues,
    { setSubmitting }: IOnSubmitInput
  ) => {
    const partnerSingleReservationApiFormValues = {
      reservationId: '',
      bookerName: ''
    };
    if (values.reservationId !== '') {
      if (/^(?=(?:\D*\d){10}\D*$)[\d\s.]+$/i.test(values.reservationId.toString())) {
        partnerSingleReservationApiFormValues.reservationId = values.reservationId;
      } else {
        partnerSingleReservationApiFormValues.bookerName = values.reservationId;
      }

      if (location.pathname === '/partner/property' && hotelPropertyCount === '1') {
        navigate({
          pathname: `/partner/hotel/reservation/${propertyHotelId}/${propertyHotelName}`,
          search: createSearchParams({
            reservationId: partnerSingleReservationApiFormValues.reservationId,
            bookerName: partnerSingleReservationApiFormValues.bookerName
          }).toString()
        });
      } else {
        navigate({
          pathname: `/partner/hotel/reservation/${hotelId}/${hotelName}`,
          search: createSearchParams({
            reservationId: partnerSingleReservationApiFormValues.reservationId,
            bookerName: partnerSingleReservationApiFormValues.bookerName
          }).toString()
        });
      }
    }

    setSubmitting(false);
  };
  return (
    <>
      <Formik
        initialValues={{
          reservationId: ''
        }}
        // validate={reservationIdValidateHandler}
        onSubmit={reservationIdSubmitHandler}>
        {({ values, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete={'off'} className={styles.reservationIdForm}>
            <img
              className={styles.searchIconImg}
              src={MagnifyingGlass}
              alt={'MagnifyingGlass icon for reservation id search'}
            />
            <Input
              inputClassName={styles.inputClassName}
              labelClassName={styles.labelDivContainer}
              className={styles.formInputContainer}
              invalidInputDivClassName={styles.invalidInputDivClassName}
              data-testid={'reservationIdFormTestId'}
              label={'reservationId'}
              id="reservationId"
              name="reservationId"
              type={'text'}
              placeholder={`${t('SearchForReservations')}`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.reservationId}
              errorType={''}
              touched={touched.reservationId}
            />
            <Button className={styles.searchButton} type={'submit'} disabled={isSubmitting}>
              {t('Search')}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};
export default SingleInputReservationForm;
