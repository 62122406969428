export const reservationStatus = {
  COMPLETED_BOOKING_OK: {
    key: 1,
    status: 'ok'
  },
  COMPLETED_BOOKING_CANCELLED_BY_GUEST: {
    key: 0,
    status: 'cancelled by guest'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_FRAUDULENT: {
    key: -2,
    status: 'cancelled by hotel (fraudulent)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_NO_CC_AT_ALL: {
    key: -4,
    status: 'cancelled by hotel (no cc at all)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_OVERBOOKING: {
    key: -5,
    status: 'cancelled by hotel (overbooking)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_HOTEL_CLOSED: {
    key: -6,
    status: 'cancelled by hotel (hotel closed)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_OTHER_REASON: {
    key: -7,
    status: 'cancelled by hotel (other reason)'
  },
  COMPLETED_BOOKING_NO_SHOW: {
    key: -8,
    status: 'no show'
  },
  COMPLETED_BOOKING_CANCELLED_REASON_UNKNOWN_OLD_SYSTEM: {
    key: -9,
    status: 'cancelled, reason unknown (probably from old system)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_INVALID_CREDIT_CARD: {
    key: -10,
    status: 'cancelled by hotel (invalid credit card)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_RESERVATION_STATEMENT: {
    key: -11,
    status: 'cancelled by hotel (reservation statement)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_BOOKINGS_TEST_BOOKING: {
    key: -12,
    status: 'deleted by BOOKING (test booking)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_BOOKINGS_FRAUDULENT: {
    key: -13,
    status: 'cancelled by BOOKING (fraudulent)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_GUEST_WAIVED_FEES: {
    key: -14,
    status: 'cancelled by guest (waived fees)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_GUEST_FORCED_CIRCUMSTANCES: {
    key: -17,
    status: 'cancelled by guest (forced circumstances)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_GUEST_PARTIAL_FEES: {
    key: -18,
    status: 'cancelled by guest (partially waived fees)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_BOOKING_BVERIFIED: {
    key: -19,
    status: 'cancelled by BOOKING (B.Verified)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_OVERBOOKING_WAIVED_COMMISSION: {
    key: -20,
    status: 'cancelled by hotel (waived commission - guest relocated)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_BOOKINGS_INVALID_PAYMENT: {
    key: -21,
    status: 'cancelled by BOOKING (unsuccessful payment)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_UNPAID_DEPOSIT: {
    key: -22,
    status: 'cancelled by hotel (unpaid deposit)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_OVERBOOKING_COMMISSIONABLE: {
    key: -23,
    status: 'cancelled by hotel (commissionable cancellation)'
  },
  COMPLETED_BOOKING_NO_SHOW_WAIVED_FEES: {
    key: -24,
    status: 'no show'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_OTHER_REASON_WAIVED_FEES: {
    key: -25,
    status: 'cancelled by hotel - waived fees (other reason)'
  }
};

export const expiryTimeLimit = 600 * 1000;

export const expiryErrorTimeLimit = 10 * 1000;

export const NO_INFO_AVAILABLE = 'No information available';

export const FAILED_TO_CONNECT = 'Failed to connect';

export const propertyTableHeadingKeyMap = {
  ID: 'id',
  'Property Name': 'propertyName'
};

export const hotelReservationTableHeadingKeyMap = {
  'Guest name': 'guestName',
  'Booker name': 'bookerName',
  'Check-in': 'checkInDate',
  'Check-out': 'checkOutDate',
  Rooms: 'noOfRooms',
  'Booked on': 'createdOn',
  Status: 'reservationstatus',
  'Booking number': 'reservationId'
};

export const paginationConstants = {
  //"sortOrder":"ASCENDING", //can have values as  DESCENDING or ASCENDING
  //"sortField": "price" // can have values as hotelReservationId,bookerName,checkin,hotelName,checkout,created,price
  pageNumber: '1',
  sortOrder: 'ASCENDING',
  sortField: 'hotelReservationId',
  pageLimit: '10'
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const shortWeekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const shortMonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
