import styles from './LanguageSelector.module.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import closeImage from '../../assets/images/Close.png';
import { ILanguage } from '../../interfaces';
import FocusTrap from 'focus-trap-react';
import { Helmet } from 'react-helmet';

interface ILanguageSelectorProps {
  availableLanguages: ILanguage[];
  changeLanguage: (lng: ILanguage) => void;
  language: ILanguage;
  toggleLanguageModal: (flag: boolean) => void;
}

const LanguageSelector = ({
  availableLanguages,
  changeLanguage,
  language,
  toggleLanguageModal
}: ILanguageSelectorProps) => {
  const { t, i18n } = useTranslation();
  const [langCode, setLangCode] = useState('');
  const languageSelected = (lng: ILanguage) => {
    changeLanguage(lng);
    toggleLanguageModal(false);
    i18n.changeLanguage(lng.code);
    setLangCode(lng.code);
  };
  const langauage_selector_close = t('langauage_selector_close');
  return (
    <FocusTrap
      focusTrapOptions={{
        delayInitialFocus: true,
        fallbackFocus: '#languageTestId'
      }}>
      <div id="languageTestId" className={styles.selectedLanguage} data-testid="languageTestId">
        <Helmet htmlAttributes={{ lang: langCode }} />
        <Helmet>
          <html lang={langCode} />
        </Helmet>
        <div
          className={styles.overlay}
          onClick={() => toggleLanguageModal(false)}
          data-testid="overlayTestId">
          <div
            className={styles.spui_modal}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            role="dialog"
            aria-modal="true"
            aria-labelledby="languageSelector">
            <div className={styles.spui_modal__header}>
              <div className={styles.spui_modal__header__title}>
                <h2 id="languageSelector">{t('select_language')}</h2>
              </div>
              <div className={styles.spui_modal__header__icon}>
                <button
                  data-testid="closeButtonTestId"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleLanguageModal(false);
                  }}
                  type="button"
                  className={styles.spui_modal__close}
                  aria-label={langauage_selector_close}>
                  <img src={closeImage} alt={'close image'}></img>
                </button>
              </div>
            </div>
            <div className={styles.spui_modal__content} data-testid="languageFlagDiv">
              {availableLanguages.map((item, index) => {
                return (
                  <div key={index} className={styles.languageItem}>
                    <a
                      key={index}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        languageSelected(item);
                      }}>
                      <div
                        className={`${styles.spui_modal__content__listItem} ${
                          item.code === language.code ? `${styles.listItemSelected}` : ''
                        }`}>
                        <div className={styles.lStart}>
                          <div className={styles.flag}>
                            <img
                              className={styles.spui_avatar__image}
                              src={item.flag}
                              alt={item.name}
                            />
                          </div>
                          <div data-testid={`title-${item.code}`} className={styles.title}>
                            {item.name}
                          </div>
                        </div>
                        {item.code === language.code && (
                          <div className={styles.lEnd}>
                            <span className={styles.modalLanguageSelect} aria-hidden="true">
                              <svg
                                height="20"
                                width="20"
                                viewBox="0 0 128 128"
                                role="presentation"
                                aria-hidden="true"
                                fill="#0071C2"
                                focusable="false">
                                <path
                                  d="M56.33 100a4 4 0 0 1-2.82-1.16L20.68 66.12a4 4 0 1 1 5.64-5.65l29.57
                                                    29.46 45.42-60.33a4 4 0 1 1 6.38 4.8l-48.17 64a4 4 0 0 1-2.91 1.6z"></path>
                              </svg>
                            </span>
                          </div>
                        )}
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};
export default LanguageSelector;
