import { ILanguage } from '../interfaces';

interface I18n {
  language: string;
}

export const getDefaultLanguageHandler = (
  i18nInstance: I18n,
  getAvailableLanguages: ILanguage[]
) => {
  let defaultLanguage = getAvailableLanguages.find(function (item: ILanguage) {
    return item.code === i18nInstance.language;
  });
  if (defaultLanguage === undefined) {
    defaultLanguage = getAvailableLanguages.find(function (item: ILanguage) {
      return item.code === 'en-GB';
    });
    if (defaultLanguage === undefined) {
      throw new TypeError('The value was promised to always be there!');
    }
  }
  return defaultLanguage;
};
