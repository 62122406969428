import styles from './Spinner.module.css';
import { usePromiseTracker } from 'react-promise-tracker';
import Backdrop from '@mui/material/Backdrop';

const Spinner = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    <div data-testid="spinnerTestId">
      {promiseInProgress && (
        <Backdrop
          data-testid={'backDropTestId'}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}>
          <div data-testid="spinner" className={styles['pos-center']}>
            <div className={styles.loader}></div>
          </div>
        </Backdrop>
      )}
    </div>
  );
};

export default Spinner;
