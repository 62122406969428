import React from 'react';
import styles from './Footer.module.css';
import { useTranslation } from 'react-i18next';
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.footer}>
      <div className={styles.termsAndPrivacyLink}>
        <button
          className={styles.termsAndPrivacyLinkButton}
          data-testid="openTermsAndConditionTestId">
          {t('terms_conditions_link')}
        </button>
        <button className={styles.termsAndPrivacyLinkButton}>{t('privacy_statement_link')}</button>
      </div>
      <p>
        {t('footer_1_1')} 1996-{new Date().getFullYear()} {t('footer_1_2')}
      </p>
      <p>{t('footer_2')}</p>
    </div>
  );
};

export default Footer;
