import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DatePickerField.module.css';
import calendarIcon from '../../../assets/images/calendarIcon.png';

type dateType = string | Date | null;

interface IDatePickerField {
  name: string;
  value: dateType;
  onChange: (name: string, val: Date | null) => void;
  id: string;
  label: string;
  dateFieldClassName?: string;
  minDate?: dateType;
  maxDate?: dateType;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate?: null;
  endDate?: null;
  dateFieldInputClassName?: string;
}

const DatePickerField = ({
  name,
  value,
  onChange,
  id,
  label,
  dateFieldClassName,
  minDate,
  maxDate,
  dateFieldInputClassName
}: IDatePickerField) => {
  return (
    <div
      className={`${styles.datePickerContainer} ${dateFieldClassName}`}
      data-testid={'datePickerFieldId'}>
      <label htmlFor={id}>{label}</label>
      <DatePicker
        id={id}
        name={id}
        className={`${styles.dateField} ${dateFieldInputClassName}`}
        selected={value as Date}
        onChange={(val) => {
          onChange(name, val);
        }}
        openToDate={new Date()}
        minDate={minDate as Date}
        maxDate={maxDate as Date}
        autoComplete={'off'}
      />
      <img className={styles.calendarIcon} src={calendarIcon} alt={'calendarIcon'} />
    </div>
  );
};

export default DatePickerField;
