import React from 'react';
import styles from './MobilePropertyTable.module.css';
import Card from '../UI/card/Card';
import FrontArrow from '../../assets/images/FrontArrow.png';
import Pagination from '../UI/pagination/Pagination';
import { useNavigate } from 'react-router-dom';

interface IUserHotelDetails {
  hotel_id: string;
  hotelName: string;
  callback_number: string;
}
interface IMobilePropertyTable {
  userHotelDetails: Array<IUserHotelDetails>;
  setMobilePageNumberHandler: (page: number | string) => void;
  totalHotelCount: number;
  mobileCurrentPageNumber: number;
  mobilePageSizeNumber: number;
}

const MobilePropertyTable = ({
  userHotelDetails,
  setMobilePageNumberHandler,
  totalHotelCount,
  mobileCurrentPageNumber,
  mobilePageSizeNumber
}: IMobilePropertyTable) => {
  const navigate = useNavigate();
  return (
    <>
      {userHotelDetails.map((property) =>
        property.hotelName ? (
          <Card
            key={property.hotel_id}
            className={styles.hotelPropertyDetailsContainer}
            onClick={() =>
              navigate(`/partner/hotel/reservation/${property.hotel_id}/${property.hotelName}`)
            }>
            <div data-testid={`hotelPropertyDetail_${property.hotel_id}`}>
              <p className={styles.hotelNameText}>
                <strong>
                  {property.hotelName ? property.hotelName : 'There is no upcoming reservations!'}
                </strong>{' '}
                <span className={styles.hotelIdText}>(ID:{property.hotel_id})</span>
              </p>
            </div>
            <img src={FrontArrow} alt={'Front arrow'} />
          </Card>
        ) : (
          <Card key={property.hotel_id} className={styles.hotelPropertyDetailsContainer}>
            <div data-testid={`hotelPropertyDetail_${property.hotel_id}`}>
              <p className={styles.hotelNameText}>
                <strong>{'There is no upcoming reservations!'}</strong>{' '}
                <span className={styles.hotelIdText}>(ID:{property.hotel_id})</span>
              </p>
            </div>
            <img src={FrontArrow} alt={'Front arrow'} />
          </Card>
        )
      )}
      <div className={styles.mobilePaginationContainer}>
        <Pagination
          onPageChange={(page) => {
            setMobilePageNumberHandler(page);
          }}
          totalCount={totalHotelCount}
          currentPage={mobileCurrentPageNumber}
          pageSize={mobilePageSizeNumber}
          className={styles.mobilePaginationBarContainer}
        />
      </div>
    </>
  );
};

export default MobilePropertyTable;
