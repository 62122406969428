import React from 'react';
import styles from './MobileHotelReservationTable.module.css';
import Card from '../UI/card/Card';
import FrontArrow from '../../assets/images/FrontArrow.png';
import Pagination from '../UI/pagination/Pagination';
import { IHotelReservationTable } from '../../interfaces';
import { getDate, getShortMonthNames, getYear } from '../../utils/utils';
import { useParams } from 'react-router-dom';

interface IMobileHotelReservation extends IHotelReservationTable {
  getMobilePartnerReservationNewListHandler: (pageNumber: number, pageSize: number) => void;
  currentPage: number | string;
  pageSize: number | string;
}

const MobileHotelReservationTable = ({
  partnerReservationList,
  getMobilePartnerReservationNewListHandler,
  currentPage,
  pageSize
}: IMobileHotelReservation) => {
  const { hotelId, hotelName } = useParams();
  return (
    <div
      className={styles.MobileHotelReservationTableContainer}
      data-testid="mobileHotelReservationTableTestId">
      {partnerReservationList.reservationDetails.map((singleReservation) => (
        <a
          href={`/partner/reservationDetail/${hotelId}/${hotelName}/${singleReservation.reservationId}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: 'black', textDecoration: 'none' }}
          key={singleReservation.reservationId}>
          <Card
            // key={singleReservation.reservationId}
            className={styles.mobileHotelReservationTableCardContainer}>
            <div data-testid={`singleReservationDetail_${singleReservation.reservationId}`}>
              <div className={styles.mobileSingleReservationContainer}>
                <p className={styles.mobileSingleReservationDateText}>{`${getDate(
                  singleReservation.checkInDate
                )} ${getShortMonthNames(singleReservation.checkInDate)} ${getYear(
                  singleReservation.checkInDate
                )}`}</p>
                <p className={styles.mobileSingleReservationDateText}> - </p>
                <p className={styles.mobileSingleReservationDateText}>{`${getDate(
                  singleReservation.checkOutDate
                )} ${getShortMonthNames(singleReservation.checkOutDate)} ${getYear(
                  singleReservation.checkOutDate
                )}`}</p>
              </div>
              <p className={styles.mobileReservationGuestNameText}>
                <strong>{singleReservation.guestName}</strong>
              </p>
              <p className={styles.mobileReservationGuestNameText}>
                <strong>{singleReservation.bookerName}</strong>
              </p>
              <div className={styles.mobileSingleReservationContainer}>
                <p>Rooms: {singleReservation.noOfRooms}</p>
                <p>-</p>
                <p>
                  Status: <strong>{singleReservation.reservationstatus}</strong>
                </p>
              </div>
            </div>
            <img src={FrontArrow} alt={'Front arrow'} />
          </Card>
        </a>
      ))}
      <Card className={styles.mobileHotelReservationTablePagination}>
        <Pagination
          onPageChange={(page) => {
            getMobilePartnerReservationNewListHandler(+page, +pageSize);
          }}
          totalCount={+partnerReservationList.totalNumberOfResults}
          currentPage={+currentPage}
          pageSize={+pageSize}
          className={styles.partnerReservationPaginationBarContainer}
        />
      </Card>
    </div>
  );
};

export default MobileHotelReservationTable;
