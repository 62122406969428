import React, { useState } from 'react';
import { Table, Actionable, Stack, Icon } from '@bookingcom/bui-react';
import styles from './PropertyTable.module.css';
import { ArrowDownIcon, ArrowUpIcon } from '@bookingcom/bui-assets-react/streamline';
import { propertyTableHeadingKeyMap } from '../../utils/constant';
import { propertyTableHeaderFormat, sortFieldFormat } from '../../utils/utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IHotelDetails {
  hotel_id: string;
  hotelName: string;
  callback_number: string;
}
interface IPropertyTable {
  userHotelDetails: Array<IHotelDetails>;
  propertySortTableHandler: (key: string, order: string) => void;
}

const PropertyTable = ({ userHotelDetails, propertySortTableHandler }: IPropertyTable) => {
  const { t } = useTranslation();
  //Note: true => ascending , false => descending
  const [propertyTableSortHeading, setPropertyTableSortHeading] = useState({
    id: true,
    propertyName: true
  });
  const handleSortSwitchHandler = (key: string) => {
    const propertyTableHeadingFieldKey =
      propertyTableHeadingKeyMap[key as keyof typeof propertyTableHeadingKeyMap];
    if (propertyTableHeadingFieldKey) {
      setPropertyTableSortHeading({
        ...propertyTableSortHeading,
        [propertyTableHeadingFieldKey]:
          !propertyTableSortHeading[
            propertyTableHeadingFieldKey as keyof typeof propertyTableSortHeading
          ]
      });
      propertySortTableHandler(
        propertyTableHeaderFormat(key),
        sortFieldFormat(
          !propertyTableSortHeading[
            propertyTableHeadingFieldKey as keyof typeof propertyTableSortHeading
          ]
        )
      );
    }
  };

  const rows = userHotelDetails.map((userHotelDetail: IHotelDetails) => {
    const keys = Object.keys(userHotelDetail);
    const mapKeys = keys.filter((key) => key !== 'callback_number');
    const propertyDetails = [];
    for (const key of mapKeys) {
      let item = {};
      if (key === 'hotel_id') {
        item = {
          content: userHotelDetail[key]
        };
      } else if (key === 'hotelName') {
        if (userHotelDetail[key]) {
          item = {
            content: (
              <Link
                style={{ color: '#0071C2', textDecoration: 'none' }}
                to={`/partner/hotel/reservation/${userHotelDetail['hotel_id']}/${userHotelDetail['hotelName']}`}>
                {userHotelDetail[key]}
              </Link>
            ),
            className: `${styles.propertyNameText}`
          };
        } else {
          item = {
            content: <p>{t('Partner_no_upcoming_reservation')}</p>
          };
        }
      }
      propertyDetails.push(item);
    }
    return {
      cells: propertyDetails
    };
  });
  return (
    <>
      <Table
        headings={[
          {
            content: (
              <Stack direction={'row'} gap={1} alignItems="start">
                <Actionable
                  className={styles.propertySortTableHeading}
                  onClick={() => handleSortSwitchHandler('ID')}>
                  <Stack.Item>{t('PropertyID')}</Stack.Item>
                  <Icon
                    size={'smaller'}
                    color={'disabled'}
                    svg={propertyTableSortHeading.id ? ArrowUpIcon : ArrowDownIcon}
                  />
                </Actionable>
              </Stack>
            )
          },
          {
            content: (
              <Stack>
                <Actionable
                  className={styles.propertySortTableHeading}
                  onClick={() => handleSortSwitchHandler('Property Name')}>
                  <Stack.Item>{t('PropertyName')}</Stack.Item>
                </Actionable>
              </Stack>
            )
          }
        ]}
        rows={rows}
        overflowFade={true}
      />
    </>
  );
};

export default PropertyTable;
