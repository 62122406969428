import React from 'react';
import styles from './Splash.module.css';
import { useTranslation } from 'react-i18next';

const Splash = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.contentBox}>
      <div className={styles.splashOuterContainerStyle} data-testid="splashContainerDiv">
        <div className={styles.splashInnerContainerStyle}>
          <div className={styles.splashContent}>
            <h2 className={styles.splashHeading}>{t('message_header')}</h2>
            <div className={styles.splashContentItem}>
              <p>{t('tv_server_down_reason_text_p1')}</p>
              <br></br>
              <p>{t('pt_server_down_reason_text_p2')}</p>
              <br></br>
              <p>
                <strong>{t('partner_left_important_traveller_reservation_label')}</strong>
              </p>
              <br></br>
              <p>{t('traveller_apology_text')}</p>
              <br></br>
              <p>{t('tv_regard_text')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.divider}></div>
    </div>
  );
};

export default Splash;
