import styles from './HomePage.module.css';
import PartnerLogin from '../../components/partnerLogin/PartnerLogin';
import React, { useEffect, useState } from 'react';
import Splash from '../../components/splash/Splash';
import { Footer } from 'abu-common-components';
import classnames from 'classnames';
import Header from '../../components/header/Header';
import { useNavigate } from 'react-router-dom';
import { getBookingAdminToolConfig, sendMagicLink } from '../../apiCall';
import { getCurrentPartnerSession } from '../../utils/utils';
import { Helmet } from 'react-helmet';
const HomePage = () => {
  const splashContainerClassName = classnames(styles.mainSplashContainer, {
    [styles.removeSplashContent]: false
  });

  const navigate = useNavigate();
  const [reservationElementsConfig, setReservationElementsConfig] = useState({
    showEnableAdvanceFunctionality: false,
    showCustomerReservation: false,
    showReservationAssistance: false,
    showSisterBrandLink: false,
    showReservationAssistanceNumber: ''
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const hideAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const validUser = await getCurrentPartnerSession();
        if (typeof validUser !== 'string' && validUser?.isUserValid) {
          navigate('/partner/property', { replace: true });
        }
      } catch (err) {
        navigate('/', { replace: true });
      }
    })();
  }, []);

  useEffect(() => {
    const getAdminConfig = async () => {
      try {
        const res = await getBookingAdminToolConfig();
        if (res && res.settings.enableAdvanceFunctionallity) {
          setReservationElementsConfig({
            showEnableAdvanceFunctionality: res.settings.enableAdvanceFunctionallity,
            showCustomerReservation: res.settings.showCustLogin,
            showReservationAssistance: res.settings.showCustServiceTelephone,
            showSisterBrandLink: res.settings.showSisterBrandLink,
            showReservationAssistanceNumber: res.settings.phNos[0]
          });
        }
      } catch (e) {
        setReservationElementsConfig({
          showEnableAdvanceFunctionality: true,
          showCustomerReservation: true,
          showReservationAssistance: false,
          showSisterBrandLink: true,
          showReservationAssistanceNumber: ''
        });
      }
    };
    getAdminConfig();
  }, []);

  const sendMagicLinkHandler = async (username: string) => {
    const response = await sendMagicLink(username);
    setShowAlert(true);
    if (response.status !== 500) {
      setAlertType('Success');
    } else {
      setAlertType('Error');
    }
  };
  return (
    <>
      <Helmet>
        <title>{`Request access to your reservations | Booking.com`}</title>
      </Helmet>
      <Header />
      <div className={styles.homeGridContainer} data-testid="homePageTestId">
        <main>
          <div className={splashContainerClassName}>
            <Splash />
          </div>
          <div className={styles.reservationContainer}>
            <PartnerLogin
              showAlert={showAlert}
              alertType={alertType === 'Success' ? 'success' : 'error'}
              hideAlertHandler={hideAlert}
              reservationElementsConfig={reservationElementsConfig}
              getBookingReservationDetailsHandler={sendMagicLinkHandler}
            />
          </div>
        </main>
        <footer>
          <div className={styles.footerContainer}>
            <Footer />
          </div>
        </footer>
      </div>
    </>
  );
};

export default HomePage;
