import React from 'react';
import styles from './SinglePropertyForm.module.css';
import Input from '../UI/input/Input';
import Button from '../UI/button/Button';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

interface ISinglePropertyForm {
  singlePropertyFormValueCallbackHandler: (propertyNameOrNumberValue: string) => void;
}

interface ISinglePropertyFormValues {
  propertyNameOrNumber: string;
}

interface IOnSubmitInput {
  setSubmitting: (flag: boolean) => void;
}

const SinglePropertyForm = ({ singlePropertyFormValueCallbackHandler }: ISinglePropertyForm) => {
  const { t } = useTranslation();
  const singlePropertyFormHandler = (
    values: ISinglePropertyFormValues,
    { setSubmitting }: IOnSubmitInput
  ) => {
    singlePropertyFormValueCallbackHandler(values.propertyNameOrNumber);
    setSubmitting(false);
  };
  return (
    <>
      <Formik
        initialValues={{
          propertyNameOrNumber: ''
        }}
        onSubmit={singlePropertyFormHandler}>
        {({ values, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form
            onSubmit={handleSubmit}
            autoComplete={'off'}
            className={styles.singlePropertyFormContainer}>
            <Input
              // inputClassName={styles.inputClassName}
              labelClassName={styles.singlePropertyFormLabelDivContainer}
              className={styles.singlePropertyFormInputContainer}
              // invalidInputDivClassName={styles.invalidInputDivClassName}
              data-testid={'singlePropertyFormTestId'}
              label={'propertyNameOrNumber'}
              id="propertyNameOrNumber"
              name="propertyNameOrNumber"
              type={'text'}
              placeholder={`${t('Search_for_a_property_ID_or_name')}...`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.propertyNameOrNumber}
              errorType={''}
              touched={touched.propertyNameOrNumber}
            />

            <Button
              className={styles.singlePropertyFormSearchButton}
              type="submit"
              disabled={isSubmitting}>
              Search
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SinglePropertyForm;
