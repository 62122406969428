import React, { useState, useEffect } from 'react';
import PartnerHeader from '../../components/partnerHeader/PartnerHeader';
import AppInfo from '../../components/appInfo/AppInfo';
import styles from './PartnerReservationViewPage.module.css';
import Card from '../../components/UI/card/Card';
import HotelReservationInputs from '../../components/hotelReservationInputs/HotelReservationInputs';
import PartnerFooter from '../../components/partnerFooter/PartnerFooter';
import classnames from 'classnames';
import HotelReservationTable from '../../components/hotelReservationTable/HotelReservationTable';
import { IPartnerReservationApiFormValues } from '../../interfaces';
import { trackPromise } from 'react-promise-tracker';
import { postPartnerReservationList } from '../../apiCall';
import { useParams, useSearchParams } from 'react-router-dom';
import { paginationConstants } from '../../utils/constant';
import { getTodayDateFormat } from '../../utils/utils';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import MobileHotelReservationTable from '../../components/mobileHotelReservationTable/MobileHotelReservationTable';
import { Helmet } from 'react-helmet';
const PartnerReservationViewPage = () => {
  const checkMobileScreenFlag = useCheckMobileScreen();
  const { hotelId, hotelName } = useParams();
  const [searchParams] = useSearchParams();
  const [showMobileReservationInput, setShowMobileReservationInput] = useState(false);
  const [showNoReservationListFound, setShowNoReservationListFound] = useState(false);
  const [partnerReservationList, setPartnerReservation] = useState(Object);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [partnerHotelReservationInputs, setPartnerHotelReservationInputs] = useState(Object);

  const showMobileReservationInputHandler = (flag: boolean) => {
    setShowMobileReservationInput(flag);
  };
  const footerDivClassName = classnames(styles.partnerFooterContainer);
  const postPartnerReservationListHandler = async (values: IPartnerReservationApiFormValues) => {
    setShowNoReservationListFound(false);
    setPartnerHotelReservationInputs(values);
    await trackPromise(
      postPartnerReservationList(values)
        .then((response) => {
          if (response.responseCode === 200) {
            if (response.reservationDetails.length > 0) {
              setPartnerReservation(response);
            }
          } else {
            setPartnerReservation(response);
            throw new Error('No list found');
          }
        })
        .catch((error) => {
          error.message && setShowNoReservationListFound(true);
        })
    );
  };

  const getPartnerReservationNewListHandler = async (pageNumber: number, pgSize: number) => {
    setPageSize(pgSize);
    setCurrentPage(pageNumber);
    await postPartnerReservationListHandler({
      reservationId: partnerHotelReservationInputs.reservationId ?? '',
      hotelId: hotelId?.toString() ?? '',
      dateUntil: partnerHotelReservationInputs.dateUntil ?? getTodayDateFormat(),
      dateFrom: partnerHotelReservationInputs.dateFrom ?? getTodayDateFormat(),
      dateTypeToBeSearched: partnerHotelReservationInputs.dateTypeToBeSearched ?? 'checkin',
      bookerName: partnerHotelReservationInputs.bookerName ?? '',
      pageNumber: pageNumber.toString(),
      sortField: paginationConstants.sortField,
      sortOrder: paginationConstants.sortOrder,
      pageLimit: pgSize.toString()
    });
  };

  const getSortHotelReservationTableHandler = async (key: string, order: string) => {
    await postPartnerReservationListHandler({
      reservationId: partnerHotelReservationInputs.reservationId ?? '',
      hotelId: hotelId?.toString() ?? '',
      dateUntil: partnerHotelReservationInputs.dateUntil ?? getTodayDateFormat(),
      dateFrom: partnerHotelReservationInputs.dateFrom ?? getTodayDateFormat(),
      dateTypeToBeSearched: partnerHotelReservationInputs.dateTypeToBeSearched ?? 'checkin',
      bookerName: partnerHotelReservationInputs.bookerName ?? '',
      pageNumber: currentPage.toString(),
      sortField: key,
      sortOrder: order,
      pageLimit: pageSize.toString()
    });
  };

  useEffect(() => {
    (async () => {
      const partnerReservationListFormFromSingleProperty = {
        reservationId: '',
        bookerName: '',
        dateUntil: getTodayDateFormat(),
        dateFrom: getTodayDateFormat(),
        dateTypeToBeSearched: 'checkin'
      };
      if (searchParams.get('reservationId') !== '' && searchParams.get('bookerName') !== '') {
        partnerReservationListFormFromSingleProperty.reservationId =
          searchParams.get('reservationId') ?? '';
        partnerReservationListFormFromSingleProperty.bookerName =
          searchParams.get('bookerName') ?? '';
      } else if (searchParams.get('reservationId') !== '') {
        partnerReservationListFormFromSingleProperty.reservationId =
          searchParams.get('reservationId') ?? '';
      } else if (searchParams.get('bookerName') !== '') {
        partnerReservationListFormFromSingleProperty.bookerName =
          searchParams.get('bookerName') ?? '';
      }
      if (
        (searchParams.get('reservationId') !== '' && searchParams.get('reservationId') !== null) ||
        (searchParams.get('bookerName') !== '' && searchParams.get('reservationId') !== null)
      ) {
        partnerReservationListFormFromSingleProperty.dateUntil = '';
        partnerReservationListFormFromSingleProperty.dateFrom = '';
        partnerReservationListFormFromSingleProperty.dateTypeToBeSearched = '';
      }

      await postPartnerReservationListHandler({
        reservationId: partnerReservationListFormFromSingleProperty.reservationId,
        hotelId: hotelId?.toString() ?? '',
        dateUntil: partnerReservationListFormFromSingleProperty.dateUntil,
        dateFrom: partnerReservationListFormFromSingleProperty.dateFrom,
        dateTypeToBeSearched: partnerReservationListFormFromSingleProperty.dateTypeToBeSearched,
        bookerName: partnerReservationListFormFromSingleProperty.bookerName,
        pageNumber: paginationConstants.pageNumber,
        sortField: paginationConstants.sortField,
        sortOrder: paginationConstants.sortOrder,
        pageLimit: paginationConstants.pageLimit
      });
    })();
  }, [hotelId, hotelName, searchParams.get('reservationId'), searchParams.get('bookerName')]);

  return (
    <>
      <Helmet>
        <title>{`Reservations | ${hotelName} | Booking.com`}</title>
      </Helmet>
      <header>
        <PartnerHeader
          showMobileReservationInputFlag={showMobileReservationInput}
          showMobileReservationInputHandler={showMobileReservationInputHandler}
          propertyHotelId={hotelId?.toString()}
        />
      </header>
      <AppInfo showMobileReservationInputFlag={showMobileReservationInput} />
      <main>
        <div
          className={styles.reservationListContainer}
          data-testid="reservationListContainerTestId">
          <h1 className={styles.partnerViewPageHeaderText}>Reservations</h1>
          <HotelReservationInputs
            postPartnerReservationListHandler={postPartnerReservationListHandler}
          />
          {(showNoReservationListFound || partnerReservationList.totalNumberOfResults === 0) && (
            <Card className={styles.NoReservationDisplay}>
              Sorry, we couldn’t find any reservations for your search, please try again
            </Card>
          )}
          {partnerReservationList.responseCode === 200 &&
            partnerReservationList.reservationDetails.length > 0 && (
              <HotelReservationTable
                partnerReservationList={partnerReservationList}
                currentPage={currentPage}
                getPartnerReservationNewListHandler={getPartnerReservationNewListHandler}
                pageSize={pageSize}
                sortHotelReservationTableHandler={getSortHotelReservationTableHandler}
              />
            )}
        </div>
        {checkMobileScreenFlag && partnerReservationList.responseCode === 200 && (
          <MobileHotelReservationTable
            partnerReservationList={partnerReservationList}
            currentPage={currentPage}
            pageSize={pageSize}
            getMobilePartnerReservationNewListHandler={getPartnerReservationNewListHandler}
          />
        )}
      </main>
      <footer className={footerDivClassName}>
        <PartnerFooter />
      </footer>
    </>
  );
};

export default PartnerReservationViewPage;
