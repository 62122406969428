import { IPartnerReservationDetailApiValues, IPartnerReservationValues } from './interfaces';
import { getCurrentPartnerSession } from './utils/utils';

const userIdTokenHandler = async () => {
  const userIdToken = await getCurrentPartnerSession();
  if (typeof userIdToken !== 'string') {
    return userIdToken?.userToken;
  } else {
    return ' ';
  }
};

const getUserName = async () => {
  const userInfo = await getCurrentPartnerSession();
  if (typeof userInfo !== 'string') {
    return userInfo?.username;
  } else {
    return ' ';
  }
};
export const getBookingAdminToolConfig = async () => {
  try {
    const AdminToolGetApi: string = process.env.REACT_APP_GET_ADMIN_TOOL_API as string;
    const response = await fetch(AdminToolGetApi, { method: 'GET' });
    if (response.ok) {
      return response.json();
    }
    return response;
  } catch (error) {
    if (error instanceof Error) {
      return error.message;
    }
    return error;
  }
};

export const getPartnerPropertyList = async () => {
  try {
    const getPartnerPropertyListApi = process.env.REACT_APP_GET_PARTNER_PROPERTY_LIST_API as string;
    const response = await fetch(getPartnerPropertyListApi, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${await userIdTokenHandler()}`
      }
    });
    return response.json();
  } catch (error) {
    return (error as Error).message;
  }
};

export const sendMagicLink = async (username: string) => {
  try {
    const magicLinkEndpoint = process.env.REACT_APP_MAGIC_LINK_API as string;
    const current = new URL(window.location.href);
    const response = await fetch(magicLinkEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        redirectUri: current.href + 'magic-link'
      })
    });
    if (response.status === 202) {
      return { success: true, status: 202 };
    } else {
      console.log(response.status);
      return { success: false, status: response.status };
    }
  } catch (error) {
    return { success: false, status: 500 };
  }
};

export const postPartnerReservationList = async (values: IPartnerReservationValues) => {
  try {
    const postPartnerReservationListApi = process.env
      .REACT_APP_POST_PARTNER_RESERVATION_LIST_API as string;
    const response = await fetch(postPartnerReservationListApi, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await userIdTokenHandler()}`
      },
      body: JSON.stringify({
        reservationId: values.reservationId,
        hotelId: values.hotelId,
        dateUntil: values.dateUntil,
        dateFrom: values.dateFrom,
        dateTypeToBeSearched: values.dateTypeToBeSearched,
        bookerName: values.bookerName,
        pageNumber: values.pageNumber,
        sortField: values.sortField,
        sortOrder: values.sortOrder,
        pageLimit: values.pageLimit
      })
    });
    return response.json();
  } catch (error) {
    return (error as Error).message;
  }
};

export const postPartnerReservationDetail = async (values: IPartnerReservationDetailApiValues) => {
  try {
    const postPartnerReservationDetailApi = process.env
      .REACT_APP_POST_PARTNER_RESERVATION_DETAIL_API as string;
    const response = await fetch(postPartnerReservationDetailApi, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await userIdTokenHandler()}`
      },
      body: JSON.stringify({
        reservationId: values.reservationId,
        hotelId: values.hotelId
      })
    });
    return response.json();
  } catch (error) {
    return (error as Error).message;
  }
};

export const postPartnerLogout = async () => {
  try {
    const postPartnerLogoutApi = process.env.REACT_APP_PARTNER_LOGOUT as string;
    const response = await fetch(postPartnerLogoutApi, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await userIdTokenHandler()}`
      },
      body: JSON.stringify({
        username: await getUserName()
      })
    });
    return response.json();
  } catch (error) {
    return (error as Error).message;
  }
};
