import React, { useState } from 'react';
import { Table, Actionable, Stack, Icon } from '@bookingcom/bui-react';
import { ArrowDownIcon, ArrowUpIcon } from '@bookingcom/bui-assets-react/streamline';
import Card from '../UI/card/Card';
// import Pagination from '../UI/pagination/Pagination';
import styles from './HotelReservationTable.module.css';
// import BasicSelect from '../UI/basicSelect/BasicSelect';
import SimpleBuiPagination from '../UI/simpleBuiPagination/SimpleBuiPagination';
import {
  getShortMonthNames,
  getYear,
  getDate,
  getDateEpochTimeStampMap,
  hotelReservationTableHeaderFormat,
  sortFieldFormat
} from '../../utils/utils';
import classnames from 'classnames';
import { IHotelReservationTable } from '../../interfaces';
import { hotelReservationTableHeadingKeyMap } from '../../utils/constant';
import { useParams } from 'react-router-dom';

interface IHotelReservationList extends IHotelReservationTable {
  getPartnerReservationNewListHandler: (pageNumber: number, pageSize: number) => void;
  currentPage: number | string;
  pageSize: number | string;
  sortHotelReservationTableHandler: (key: string, order: string) => void;
}

const HotelReservationTable = ({
  partnerReservationList,
  getPartnerReservationNewListHandler,
  currentPage,
  pageSize,
  sortHotelReservationTableHandler
}: IHotelReservationList) => {
  //true => ascending , false => descending
  const [hotelReservationTableSortHeading, setHotelReservationTableSortHeading] = useState({
    guestName: true,
    checkInDate: true,
    checkOutDate: true,
    noOfRooms: true,
    createdOn: true,
    reservationstatus: true,
    reservationId: true,
    bookerName: true
  });
  const { hotelId, hotelName } = useParams();
  //Uncomment below code when original pagination issue is addressed !!!
  // const getPageStart = (pageLimit: number, pageNr: number) => {
  //   return pageLimit * pageNr;
  // };
  // const getPageLabel = (total: number, pageLimit: number, pageNr: number) => {
  //   const start = Math.max(getPageStart(pageLimit, pageNr), 0);
  //   const end = Math.min(getPageStart(pageLimit, pageNr + 1), total);
  //
  //   return `${start + 1} - ${end}`;
  // };
  // const itemsToShow = Array.from(
  //   { length: +partnerReservationList.totalNumberOfResults },
  //   (_, i) => `Item ${i + 1}`
  // );
  // const intervals = Array.from({ length: Math.ceil(itemsToShow.length / +pageSize) }, (_, i) =>
  //   getPageLabel(itemsToShow.length, +pageSize, i)
  // );
  const handleSwitch = (key: string) => {
    const hotelReservationTableHeadingFieldKey =
      hotelReservationTableHeadingKeyMap[key as keyof typeof hotelReservationTableHeadingKeyMap];
    if (hotelReservationTableHeadingFieldKey) {
      setHotelReservationTableSortHeading({
        ...hotelReservationTableSortHeading,
        [hotelReservationTableHeadingFieldKey]:
          !hotelReservationTableSortHeading[
            hotelReservationTableHeadingFieldKey as keyof typeof hotelReservationTableSortHeading
          ]
      });
      sortHotelReservationTableHandler(
        hotelReservationTableHeaderFormat(key),
        sortFieldFormat(
          !hotelReservationTableSortHeading[
            hotelReservationTableHeadingFieldKey as keyof typeof hotelReservationTableSortHeading
          ]
        )
      );
    }
  };
  //Uncomment below code when original pagination issue is addressed !!!
  // const changePartnerReservationPageSizeHandler = (size: number) => {
  //   getPartnerReservationNewListHandler(1, size);
  // };

  const rows = partnerReservationList.reservationDetails.map((singleReservation) => {
    const reservationList = [];
    const singleReservationMap = {
      guestName: singleReservation.guestName,
      bookerName: singleReservation.bookerName,
      checkInDate: `${getDate(singleReservation.checkInDate)} ${getShortMonthNames(
        singleReservation.checkInDate
      )} ${getYear(singleReservation.checkInDate)}`,
      checkOutDate: `${getDate(singleReservation.checkOutDate)} ${getShortMonthNames(
        singleReservation.checkOutDate
      )} ${getYear(singleReservation.checkOutDate)}`,
      noOfRooms: singleReservation.noOfRooms,
      createdOn: getDateEpochTimeStampMap(singleReservation.createdOn),
      reservationstatus: singleReservation.reservationstatus,
      reservationId: singleReservation.reservationId
    };
    for (const key in singleReservationMap) {
      let reservation = {};
      if (key === 'guestName') {
        reservation = {
          content: (
            <a
              href={`/partner/reservationDetail/${hotelId}/${hotelName}/${singleReservation.reservationId}`}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#0071C2', textDecoration: 'none' }}>
              {singleReservationMap[key]}
            </a>
          )
        };
      } else if (
        key === 'bookerName' ||
        key === 'checkInDate' ||
        key === 'checkOutDate' ||
        key === 'noOfRooms' ||
        key === 'createdOn'
      ) {
        reservation = {
          content: singleReservationMap[key]
        };
      } else if (key === 'reservationstatus') {
        reservation = {
          content: singleReservationMap[key],
          className: classnames(styles.bookingOkStatusText, {
            [styles.bookingCancelStatusText]: singleReservationMap[key] === 'cancelled'
          })
        };
      } else if (key === 'reservationId') {
        reservation = {
          content: (
            <a
              href={`/partner/reservationDetail/${hotelId}/${hotelName}/${singleReservation.reservationId}`}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#0071C2', textDecoration: 'none' }}>
              {singleReservationMap[key]}
            </a>
          )
        };
      }
      reservationList.push(reservation);
    }

    return {
      cells: reservationList
    };
  });
  return (
    <Card className={styles.hotelReservationTableCardContainer}>
      <Table
        headings={[
          {
            content: (
              <Stack>
                <Actionable onClick={() => handleSwitch('Guest name')}>
                  <Stack.Item>Guest name</Stack.Item>
                </Actionable>
              </Stack>
            )
          },
          {
            content: (
              <Stack>
                <Actionable onClick={() => handleSwitch('Booker name')}>
                  <Stack.Item>Booker name</Stack.Item>
                </Actionable>
              </Stack>
            )
          },
          {
            content: (
              <Stack direction={'row'} gap={1} alignItems={'start'}>
                <Actionable onClick={() => handleSwitch('Check-in')}>
                  <Stack.Item>Check-in</Stack.Item>
                </Actionable>
                <Icon
                  size={'smaller'}
                  color={'disabled'}
                  svg={
                    hotelReservationTableSortHeading.checkInDate ? ArrowUpIcon : ArrowDownIcon
                  }></Icon>
              </Stack>
            )
          },
          {
            content: (
              <Stack>
                <Actionable onClick={() => handleSwitch('Check-out')}>
                  <Stack.Item>Check-out</Stack.Item>
                </Actionable>
              </Stack>
            )
          },
          {
            content: (
              <Stack>
                <Actionable onClick={() => handleSwitch('Rooms')}>
                  <Stack.Item>Rooms</Stack.Item>
                </Actionable>
              </Stack>
            )
          },
          {
            content: (
              <Stack>
                <Actionable onClick={() => handleSwitch('Booked on')}>
                  <Stack.Item>Booked on</Stack.Item>
                </Actionable>
              </Stack>
            )
          },
          {
            content: (
              <Stack>
                <Actionable onClick={() => handleSwitch('Status')}>
                  <Stack.Item>Status</Stack.Item>
                </Actionable>
              </Stack>
            )
          },
          {
            content: (
              <Stack>
                <Actionable onClick={() => handleSwitch('Booking number')}>
                  <Stack.Item>Booking number</Stack.Item>
                </Actionable>
              </Stack>
            )
          }
        ]}
        rows={rows}
        overflowFade={true}
      />
      {+partnerReservationList.totalNumberOfResults > pageSize && (
        //Comment or remove below code when original pagination issue is addressed !!!
        // <div className={styles.simpleBuiPaginationComponentContainer}>
        <SimpleBuiPagination
          currentPage={+currentPage}
          onPageChange={(page) => {
            getPartnerReservationNewListHandler(Number(page), +pageSize);
          }}
          pageSize={+pageSize}
          totalCount={+partnerReservationList.totalNumberOfResults}
          // className={styles.partnerReservationPaginationBarContainer}
        />
        // </div>
        //Uncomment below code when original pagination issue is addressed !!!
        // <div
        //   className={styles.partnerReservationListPaginationContainer}
        //   data-testid="reservationTablePaginationTestId">
        //   <Pagination
        //     onPageChange={(page) => {
        //       getPartnerReservationNewListHandler(Number(page), +pageSize);
        //     }}
        //     totalCount={+partnerReservationList.totalNumberOfResults}
        //     currentPage={+currentPage}
        //     pageSize={+pageSize}
        //     className={styles.partnerReservationPaginationBarContainer}
        //   />
        //   <div className={styles.partnerTotalNumberResultsContainer}>
        //     <p>
        //       {intervals[+currentPage - 1]} of {partnerReservationList.totalNumberOfResults} results
        //     </p>
        //   </div>
        //   <div data-testid="reservationBasicSelectTestId">
        //     <BasicSelect
        //       changePageSizeHandler={changePartnerReservationPageSizeHandler}
        //       pageSize={+pageSize}
        //     />
        //   </div>
        // </div>
      )}
    </Card>
  );
};

export default HotelReservationTable;
