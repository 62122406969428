import React, { useState, useEffect } from 'react';
import PartnerHeader from '../../components/partnerHeader/PartnerHeader';
import AppInfo from '../../components/appInfo/AppInfo';
import styles from './PartnerLandingPage.module.css';
import MultipleProperties from '../../components/multipleProperties/MultipleProperties';
import PartnerAssistance from '../../components/partnerAssistance/PartnerAssistance';
import PartnerFooter from '../../components/partnerFooter/PartnerFooter';
import SingleProperty from '../../components/singleProperty/SingleProperty';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import classnames from 'classnames';
import { trackPromise } from 'react-promise-tracker';
import { getPartnerPropertyList } from '../../apiCall';

const PartnerLandingPage = () => {
  const checkMobileScreenFlag = useCheckMobileScreen();
  const [showMobileReservationInput, setShowMobileReservationInput] = useState(false);
  const [partnerPropertyList, setPartnerPropertyList] = useState(Object);
  const footerDivClassName = classnames(styles.partnerFooterContainer, {
    [styles.footerContainerDiv]: checkMobileScreenFlag && showMobileReservationInput
  });
  const showMobileReservationInputHandler = (flag: boolean) => {
    setShowMobileReservationInput(flag);
  };
  useEffect(() => {
    (async () => {
      await trackPromise(
        getPartnerPropertyList().then((response) => {
          if (response.responseCode === 200) {
            setPartnerPropertyList(response);
          }
        })
      );
    })();
  }, []);

  return (
    <>
      <header>
        <PartnerHeader
          showMobileReservationInputFlag={showMobileReservationInput}
          showMobileReservationInputHandler={showMobileReservationInputHandler}
          propertyCount={
            partnerPropertyList.properties
              ? partnerPropertyList?.properties?.propertyCount.toString()
              : ''
          }
          propertyHotelId={
            partnerPropertyList.properties
              ? partnerPropertyList.properties.hotels[0].hotel_id.toString()
              : ''
          }
          propertyHotelName={
            partnerPropertyList.properties
              ? partnerPropertyList.properties.hotels[0].hotelName.toString()
              : ''
          }
        />
      </header>
      <AppInfo showMobileReservationInputFlag={showMobileReservationInput} />
      <main>
        {partnerPropertyList && partnerPropertyList?.properties?.propertyCount === 1 && (
          <SingleProperty
            showMobileReservationInputFormFlag={showMobileReservationInput}
            checkMobileScreenFlag={checkMobileScreenFlag}
            singlePropertyHotelId={partnerPropertyList.properties.hotels[0].hotel_id}
            singlePropertyHotelName={partnerPropertyList.properties.hotels[0].hotelName}
          />
        )}
        {partnerPropertyList && partnerPropertyList?.properties?.propertyCount > 1 && (
          <MultipleProperties partnerPropertyList={partnerPropertyList} />
        )}
        {partnerPropertyList?.properties?.propertyCount && (
          <PartnerAssistance showMobileReservationInputFormFlags={showMobileReservationInput} />
        )}
      </main>
      <footer className={footerDivClassName}>
        {partnerPropertyList?.properties?.propertyCount && <PartnerFooter />}
      </footer>
    </>
  );
};

export default PartnerLandingPage;
