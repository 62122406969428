import React from 'react';
import styles from './ReservationDetailCard.module.css';
import Card from '../UI/card/Card';
import {
  getDate,
  getNumberOfNights,
  getReservationDetailBookedOnDateEpochTimeStampMap,
  getShortMonthNames,
  getShortWeekDays,
  getYear,
  reformatReservationId
} from '../../utils/utils';
import { IDetail } from '../../interfaces';
import { useTranslation } from 'react-i18next';

interface IReservationDetailCard {
  partnerReservationDetail: IDetail;
}

const ReservationDetailCard = ({ partnerReservationDetail }: IReservationDetailCard) => {
  const { t } = useTranslation();
  return (
    <Card className={styles.reservationDetailContainer}>
      <div
        className={styles.reservationDetailInnerContainer}
        data-testid="reservationDetailCardTestId">
        <div className={styles.reservationDetailCheckInContainer}>
          <p className={styles.headerDetailText}>{t('Pt-Check-in')}</p>
          <p className={styles.detailDateText}>
            <strong>{`${getShortWeekDays(partnerReservationDetail.checkInDate)} ${getDate(
              partnerReservationDetail.checkInDate
            )} ${getShortMonthNames(partnerReservationDetail.checkInDate)} ${getYear(
              partnerReservationDetail.checkInDate
            )}`}</strong>
          </p>
          <br />
          <p className={styles.headerDetailText}>{t('Pt-Check-out')}</p>
          <p className={styles.detailDateText}>
            <strong>{`${getShortWeekDays(partnerReservationDetail.checkOutDate)} ${getDate(
              partnerReservationDetail.checkOutDate
            )} ${getShortMonthNames(partnerReservationDetail.checkOutDate)} ${getYear(
              partnerReservationDetail.checkOutDate
            )}`}</strong>
          </p>
          <br />
          <p className={styles.headerDetailText}>{t('NumberOfNights')}:</p>
          <p>
            <strong>{`${getNumberOfNights(
              partnerReservationDetail.checkInDate,
              partnerReservationDetail.checkOutDate
            )}`}</strong>
          </p>
        </div>
        <div className={styles.reservationDetailGuestContainer}>
          <p className={styles.headerDetailText}>{t('BookerName')}:</p>
          <p className={styles.detailGuestNameText}>
            <strong>
              {partnerReservationDetail.bookerFirstName} {partnerReservationDetail.bookerLastName}
            </strong>
          </p>
          <br />
          <p className={styles.headerDetailText}>{t('Email address')}:</p>
          <p>{partnerReservationDetail.bookerEmail}</p>
          <br />
          <p className={styles.headerDetailText}>{t('PreferredLanguage')}:</p>
          <p>{partnerReservationDetail.guestLanguage}</p>
        </div>
        <div className={styles.reservationDetailBookerContainer}>
          <br />
          <p>{t('BookingNumber')}:</p>
          <p>{reformatReservationId(partnerReservationDetail.reservationId)}</p>
          <br />
          <p className={styles.headerDetailText}>{t('Pt-Received')}</p>
          <p>
            {getReservationDetailBookedOnDateEpochTimeStampMap(partnerReservationDetail.createdOn)}
          </p>
          <br />
          <p>{t('Status')}</p>
          <p>
            <strong>{partnerReservationDetail.reservationstatus.toUpperCase()}</strong>
          </p>
        </div>
      </div>
    </Card>
  );
};

export default ReservationDetailCard;
