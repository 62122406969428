import React, { ChangeEventHandler } from 'react';
import styles from './ToggleSwitch.module.css';

interface Props {
  onToggleInput: (cookieType: string, status: boolean) => void;
  currentState: boolean;
  cookieType: string;
}

const ToggleSwitch = ({ currentState, onToggleInput, cookieType }: Props) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = () => {
    onToggleInput(cookieType, !currentState);
  };
  return (
    <div className={styles.cookieBanner} data-testid="toggleSwitchTestId">
      <label className={styles.switch}>
        <input
          className={styles.input}
          checked={currentState}
          type="checkbox"
          onChange={handleChange}
        />
        <span className={styles.sliderRound}></span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
