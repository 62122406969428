import commonCurrency from '../data/commonCurrency.json';
const getNumberOfMilliseconds = 8.64e7; // 8.64e7 is the number of milliseconds in a day.
import { Auth } from 'aws-amplify';
import { monthNames, days, shortMonthNames, shortWeekdays, reservationStatus } from './constant';

export const getMonth = (epochDate: string) => {
  const dateFormat = new Date(+epochDate * getNumberOfMilliseconds);
  const month = monthNames[dateFormat.getMonth()];
  return `${month}`;
};

export const getShortMonthNames = (epochDate: string) => {
  const dateFormat = new Date(+epochDate * getNumberOfMilliseconds);
  const month = shortMonthNames[dateFormat.getMonth()];
  return `${month}`;
};

export const getYear = (epochDate: string) => {
  const dateFormat = new Date(+epochDate * getNumberOfMilliseconds);
  const fullYear = dateFormat.getFullYear();
  return `${fullYear}`;
};

export const getDay = (epochDate: string) => {
  const dateFormat = new Date(+epochDate * getNumberOfMilliseconds);
  const day = days[dateFormat.getDay()];
  return `${day}`;
};

export const getShortWeekDays = (epochDate: string) => {
  const dateFormat = new Date(+epochDate * getNumberOfMilliseconds);
  const shortWeekday = shortWeekdays[dateFormat.getDay()];
  return `${shortWeekday}`;
};

export const getDate = (epochDate: string) => {
  const dateFormat = new Date(+epochDate * getNumberOfMilliseconds);
  const date = dateFormat.getDate();
  return `${date}`;
};

export const getReservationStatus = (status: number) => {
  return (Object as any)
    .values(reservationStatus)
    .find((item: { key: number; status: string }) => item.key === status).status;
};

export const getCurrenySymbol = (currencyCode: string) => {
  return (Object as any)
    .values(commonCurrency)
    .find((item: { code: string }) => item.code === currencyCode).symbol;
};

export const getNumberOfNights = (checkInEpoch: string, checkOutEpoch: string) => {
  const checkInDate = new Date(+checkInEpoch * getNumberOfMilliseconds).toISOString();
  const checkOutDate = new Date(+checkOutEpoch * getNumberOfMilliseconds).toISOString();
  const timeDiff = Math.abs(new Date(checkInDate).getTime() - new Date(checkOutDate).getTime());
  return Math.ceil(timeDiff / (1000 * 3600 * 24));
};

export const userPropertySliceHandler = (
  page: number,
  pageLimit: number,
  list: { hotel_id: string; hotelName: string; callback_number: string }[]
) => {
  const startIndex = (page - 1) * pageLimit;
  return list.slice(startIndex, startIndex + pageLimit);
};

export const sortFieldFormat = (order: boolean) => {
  if (order) {
    return 'ASCENDING';
  } else {
    return 'DESCENDING';
  }
};

export const reversePropertySortFieldFormat = (order: string) => {
  switch (order) {
    case 'ASCENDING':
      return true;
    case 'DESCENDING':
      return false;
    default:
      return true;
  }
};

export const propertyTableHeaderFormat = (key: string) => {
  //ID , Property name
  switch (key) {
    case 'ID':
      return 'hotel_id';
    case 'Property Name':
      return 'hotelName';
    default:
      return 'hotel_id';
  }
};

export const getCurrentPartnerSession = async () => {
  try {
    const partnerAuthSession = await Auth.currentSession();
    const { username } = await Auth.currentUserInfo();
    return {
      isUserValid: partnerAuthSession.isValid(),
      userToken: partnerAuthSession.getIdToken().getJwtToken(),
      username: username
    };
  } catch (err) {
    return (err as Error).message;
  }
};

export const getTodayDateFormat = () => {
  const todayDate = new Date();
  return `${todayDate.getFullYear()}-${(todayDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${todayDate.getDate().toString().padStart(2, '0')}`;
};

export const getDateFormat = (dateFromOrUntil: Date) => {
  return `${dateFromOrUntil.getFullYear()}-${(dateFromOrUntil.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${dateFromOrUntil.getDate().toString().padStart(2, '0')}`;
};

export const getDateEpochTimeStampMap = (epochTimeStamp: string) => {
  const dateFormat = new Date(+epochTimeStamp * 1000);
  return `${dateFormat.getDate()} ${
    shortMonthNames[dateFormat.getMonth()]
  } ${dateFormat.getFullYear()}`;
};

export const getReservationDetailBookedOnDateEpochTimeStampMap = (epochTimeStamp: string) => {
  const dateFormat = new Date(+epochTimeStamp * 1000);
  return `${shortWeekdays[dateFormat.getDay()]} ${dateFormat.getDate()} ${
    shortMonthNames[dateFormat.getMonth()]
  } ${dateFormat.getFullYear()}`;
};

export const hotelReservationTableHeaderFormat = (key: string) => {
  // Note: sort order search key => hotelReservationId,bookerName,checkin,hotelName,checkout,created,price
  switch (key) {
    case 'Guest name':
      return 'bookerName';
    case 'Booker name':
      return 'bookerName';
    case 'Check-in':
      return 'checkin';
    case 'Check-out':
      return 'checkout';
    case 'Rooms':
      return 'noOfRooms';
    case 'Booked on':
      return 'created';
    case 'Status':
      return 'status';
    case 'Booking number':
      return 'hotelReservationId';
    default:
      return 'hotelReservationId';
  }
};

export const getDateTypeTobeSearchedForApi = (value: string) => {
  if (value === 'Check-in') {
    return 'checkin';
  } else {
    return 'checkout';
  }
};

export const reformatReservationId = (id: string) => {
  const idArray = Array.from(id);
  return `${idArray.slice(0, 4).join('')}.${idArray.slice(4, 7).join('')}.${idArray
    .slice(7, 10)
    .join('')}`;
};
