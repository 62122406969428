import React, { MouseEventHandler } from 'react';
import styles from './Card.module.css';

interface ICard {
  children?: React.ReactNode;
  className?: string;
  onClick?: MouseEventHandler;
}
const Card = ({ children, className, onClick }: ICard) => {
  return (
    <div
      data-testid={'cardTestId'}
      className={`${styles.cardContainer} ${className}`}
      onClick={onClick}>
      {children}
    </div>
  );
};

export default Card;
