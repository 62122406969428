import React from 'react';
import styles from './AppInfo.module.css';
import infoSign from '../../assets/images/Info-sign.png';
import classnames from 'classnames';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { useTranslation } from 'react-i18next';

interface IAppInfo {
  showMobileReservationInputFlag?: boolean;
}

const AppInfo = ({ showMobileReservationInputFlag }: IAppInfo) => {
  const { t } = useTranslation();
  const appInfoContainerDiv = classnames(styles.appInfoContainer, {
    [styles.appInfoContainerDiv]: useCheckMobileScreen() && showMobileReservationInputFlag
  });
  return (
    <>
      <div className={appInfoContainerDiv} data-testid={'appInfoDivTestId'}>
        <img className={styles.infoSign} src={infoSign} alt={'Info sign'} />
        <div>
          <span>
            <strong>{t('message_header')}</strong>
          </span>
          <p className={styles.alertInfo}>
            We are currently experiencing technical issues with our platform. We are working hard to
            solve the problem and we hope to be back online again as soon as possible. All confirmed
            reservations made through our platform are still valid and active.
          </p>
        </div>
      </div>
    </>
  );
};

export default AppInfo;
