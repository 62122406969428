import React from 'react';
import { useTranslation } from 'react-i18next';

const PartnerFooter = () => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t('terms_conditions_link')}</p>
      <p>{t('Partner_Privacy_Statement')}</p>
    </>
  );
};

export default PartnerFooter;
