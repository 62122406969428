import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { BUIProvider } from '@bookingcom/bui-react';
import legacyTheme from '@bookingcom/bui-react/themes/legacy';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <BUIProvider theme={legacyTheme}>
        <App />
      </BUIProvider>
    </BrowserRouter>
  </React.StrictMode>
);
