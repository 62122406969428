import React from 'react';
import styles from './SingleProperty.module.css';
import Card from '../UI/card/Card';
import Button from '../UI/button/Button';
import MobileSingleReservationForm from '../mobileSingleReservationForm/MobileSingleReservationForm';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
interface ISingleProperty {
  showMobileReservationInputFormFlag: boolean;
  checkMobileScreenFlag: boolean;
  singlePropertyHotelId: string;
  singlePropertyHotelName: string;
}

const SingleProperty = ({
  showMobileReservationInputFormFlag,
  checkMobileScreenFlag,
  singlePropertyHotelId,
  singlePropertyHotelName
}: ISingleProperty) => {
  const { t } = useTranslation();
  const singlePropertyContainerClassnames = classnames(styles.singlePropertyContainer, {
    [styles.singlePropertyContainerDiv]: checkMobileScreenFlag && showMobileReservationInputFormFlag
  });
  const navigate = useNavigate();
  return (
    <section className={styles.singlePropertySection} aria-label="view user property">
      <Helmet>
        <title>{`${singlePropertyHotelName} | Booking.com`}</title>
      </Helmet>
      <Card className={singlePropertyContainerClassnames}>
        <section className={styles.singlePropertyContent}>
          <h1 style={{ fontSize: '20px' }}>
            <span style={{ fontWeight: '400' }}>{t('Pt-Welcome')}</span> {singlePropertyHotelName}
          </h1>
          <p>{t('Pt-singleProperty_landing_content_1')}</p>
          <br />
          <p>{t('Pt-singleProperty_landing_content_2')}</p>
          <br />
          <Button
            className={styles.partnerListViewButton}
            type={'button'}
            onClick={() =>
              navigate(
                `/partner/hotel/reservation/${singlePropertyHotelId}/${singlePropertyHotelName}`
              )
            }>
            {t('SeeTodaysArrival')}
          </Button>
        </section>
      </Card>
      {showMobileReservationInputFormFlag && checkMobileScreenFlag && (
        <MobileSingleReservationForm />
      )}
    </section>
  );
};

export default SingleProperty;
