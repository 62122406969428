import React, { MouseEventHandler, useState } from 'react';
import ToggleSwitch from '../toggleSwitch/ToggleSwitch';
import styles from './PrivacySettings.module.css';
import closeImage from '../../assets/images/Close.png';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';

interface Props {
  togglePrivacySetting: (status: boolean) => void;
}
const PrivacySettings = ({ togglePrivacySetting }: Props) => {
  const handlePrivacySettings: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    togglePrivacySetting(false);
  };
  const [selectedOption, setSelectedOption] = useState('privacy');
  const [cookieState, setCookieState] = useState({
    functional: true
  });
  const cookieHandler = (cookieType: string, newState: boolean) => {
    setCookieState({
      ...cookieState,
      [cookieType]: newState
    });
  };

  const selectedOptionsClassNames = (selectedClassOptions: string) => {
    if (selectedOption === selectedClassOptions) {
      return classnames(styles.leftItem, styles.itemSelected);
    } else {
      return classnames(styles.leftItem);
    }
  };
  return (
    <FocusTrap
      focusTrapOptions={{
        delayInitialFocus: true,
        fallbackFocus: '#testid'
      }}>
      <div
        id="testid"
        tabIndex={-1}
        className={styles.settings}
        data-testid="privacySettingsTestId">
        <div className={styles.settingsContent}>
          <div className={styles.header}>
            <header className={styles.headerTitle}>Manage your privacy settings</header>
            <div className={styles.headerClose}>
              <button
                tabIndex={1}
                data-testid="closeButtonTestId"
                onClick={handlePrivacySettings}
                className={styles.headerCloseBtn}
                aria-label={'Close privacy settings'}>
                <img src={closeImage} alt={'Close settings'}></img>
              </button>
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.left}>
              <ul
                className={styles.cookieSettingsOptions}
                aria-label={'booking privacy settings options'}>
                <li
                  className={selectedOptionsClassNames('privacy')}
                  onClick={() => setSelectedOption('privacy')}
                  data-testid="yourPrivacySubTabTestId">
                  Your Privacy
                </li>
                <li
                  className={selectedOptionsClassNames('functional')}
                  onClick={() => setSelectedOption('functional')}
                  data-testid="functionalSubTabTestId">
                  Functional Cookies
                </li>
              </ul>
            </div>

            <div className={styles.right}>
              {selectedOption === 'privacy' && (
                <div data-testid="yourPrivacySubTestId">
                  <div className={styles.rightTitle}>
                    <div>
                      <h3>Your Privacy</h3>
                    </div>
                  </div>
                  <div className={styles.rightContent}>
                    <p className={styles.rightContentParagraph}>
                      Select which cookies you’d like to accept on this site. You can find more
                      detailed information on cookie use and descriptions in our privacy and cookie
                      policy.
                    </p>
                  </div>
                </div>
              )}
              {selectedOption === 'functional' && (
                <div data-testid="functionalSubTestId">
                  <div className={styles.rightTitle}>
                    <div>
                      <h3>Functional cookies</h3>
                    </div>
                    <div>
                      <ToggleSwitch
                        currentState={cookieState.functional}
                        onToggleInput={cookieHandler}
                        cookieType={'functional'}></ToggleSwitch>
                    </div>
                  </div>
                  <div className={styles.rightContent}>
                    <p className={styles.rightContentParagraph}>
                      Functional cookies enable our website to work properly, so you can create your
                      account, sign in, and manage your bookings. They also remember your selected
                      currency, language, past searches and other preferences. These technical
                      cookies must be enabled in order to use our site and services.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};

export default PrivacySettings;
