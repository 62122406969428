import React from 'react';
import styles from './MobileReservationDetailCard.module.css';
import Card from '../UI/card/Card';
import { IDetail } from '../../interfaces';
import {
  getDate,
  getNumberOfNights,
  getReservationDetailBookedOnDateEpochTimeStampMap,
  getShortMonthNames,
  getShortWeekDays,
  getYear,
  reformatReservationId
} from '../../utils/utils';
import { useTranslation } from 'react-i18next';

interface IMobileReservationDetailCard {
  partnerReservationDetail: IDetail;
}

const MobileReservationDetailCard = ({
  partnerReservationDetail
}: IMobileReservationDetailCard) => {
  const { t } = useTranslation();
  return (
    <Card className={styles.mobileReservationDetailCardContainer}>
      <p className={styles.mobileHeaderDetailText}>{t('Guest name')}:</p>
      <p className={styles.mobileDetailGuestNameText}>
        <strong>{partnerReservationDetail.guestName}</strong>
      </p>
      <br />
      <p className={styles.mobileHeaderDetailText}>{t('Email address')}s:</p>
      <p>{partnerReservationDetail.bookerEmail}</p>
      <br />
      <div
        className={styles.mobileReservationCheckinAndCheckoutContainer}
        data-testid={'mobileReservationCheckinAndCheckoutContainerTestId'}>
        <div>
          <p className={styles.mobileHeaderDetailText}>{t('Pt-Check-in')}</p>
          <p className={styles.mobileDetailDateText}>
            <strong>{`${getShortWeekDays(partnerReservationDetail.checkInDate)} ${getDate(
              partnerReservationDetail.checkInDate
            )} ${getShortMonthNames(partnerReservationDetail.checkInDate)} ${getYear(
              partnerReservationDetail.checkInDate
            )}`}</strong>
          </p>
        </div>
        <div>
          <p className={styles.mobileHeaderDetailText}>{t('Pt-Check-out')}</p>
          <p className={styles.mobileDetailDateText}>
            <strong>{`${getShortWeekDays(partnerReservationDetail.checkOutDate)} ${getDate(
              partnerReservationDetail.checkOutDate
            )} ${getShortMonthNames(partnerReservationDetail.checkOutDate)} ${getYear(
              partnerReservationDetail.checkOutDate
            )}`}</strong>
          </p>
        </div>
      </div>
      <br />
      <p className={styles.headerDetailText}>{t('NumberOfNights')}:</p>
      <p className={styles.mobileDetailValueText}>
        <strong>{`${getNumberOfNights(
          partnerReservationDetail.checkInDate,
          partnerReservationDetail.checkOutDate
        )}`}</strong>
      </p>
      <br />
      <div
        className={styles.mobileBookingNumberAndBookedOnContainer}
        data-testid={'mobileBookingNumberAndBookedOnContainerTestId'}>
        <div>
          <p className={styles.mobileHeaderDetailText}>{t('BookingNumber')}:</p>
          <p className={styles.mobileDetailValueText}>
            {reformatReservationId(partnerReservationDetail.reservationId)}
          </p>
        </div>
        <div>
          <p className={styles.mobileHeaderDetailText}>{t('Pt-Received')}</p>
          <p className={styles.mobileDetailValueText}>
            {getReservationDetailBookedOnDateEpochTimeStampMap(partnerReservationDetail.createdOn)}
          </p>
        </div>
      </div>
      <br />
      <p>{t('Status')}</p>
      <p className={styles.mobileDetailValueText}>
        <strong>{partnerReservationDetail.reservationstatus.toUpperCase()}</strong>
      </p>
    </Card>
  );
};

export default MobileReservationDetailCard;
