import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FormInput.module.css';
import VectorError from '../../../assets/images/Vector.png';

interface IFormProps {
  label: string;
  id: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler;
  errorType: string | undefined;
  touched: boolean | undefined;
  type: 'text' | 'date';
  inputFormClassName?: string;
}

const FormInput = (props: IFormProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.formInput} ${props.inputFormClassName}`}
      data-testid="formInputTestId">
      <label htmlFor={props.id} data-testid="inputLabel">
        {props.label}
      </label>
      <div>
        <input
          style={props.errorType ? { border: '1px solid #CC0000' } : {}}
          id={props.id}
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
        />
        {props.errorType && props.touched && (
          <img className={styles.vectorImg} src={VectorError} alt={'errorInput'} />
        )}
      </div>
      {props.errorType && props.touched && (
        <div className={styles.invalidInput} data-testid="errorMessage" role={'alert'}>
          {t(`isInvalidError.${props.id}`)}
        </div>
      )}
    </div>
  );
};

export default FormInput;
