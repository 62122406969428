import React, { useState, useEffect, useRef } from 'react';
import styles from './PartnerReservationDetailViewPage.module.css';
import { useParams, useNavigate } from 'react-router-dom';
import { postPartnerReservationDetail } from '../../apiCall';
import { trackPromise } from 'react-promise-tracker';
import PartnerHeader from '../../components/partnerHeader/PartnerHeader';
import AppInfo from '../../components/appInfo/AppInfo';
import Card from '../../components/UI/card/Card';
import Button from '../../components/UI/button/Button';
import ReservationDetailCard from '../../components/reservationDetailCard/ReservationDetailCard';
import MobileReservationDetailCard from '../../components/mobileReservationDetailCard/MobileReservationDetailCard';
import PartnerFooter from '../../components/partnerFooter/PartnerFooter';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { Helmet } from 'react-helmet';
import { useReactToPrint } from 'react-to-print';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

interface IRoomDetail {
  roomType: string;
  nrGuests: string;
  maxCapacity: string;
  petAndChildrenPolicy: string;
  cancellationPolicyFees: string;
  reservationPrice: string;
  guestName: string;
  roomStatus: string;
}

const PartnerReservationDetailViewPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const checkMobileScreenFlag = useCheckMobileScreen();
  const { hotelId, hotelName, reservationId } = useParams();
  const [partnerReservationDetail, setPartnerReservationDetail] = useState(Object);
  const [showMobileReservationInput, setShowMobileReservationInput] = useState(false);

  useEffect(() => {
    (async () => {
      await trackPromise(
        postPartnerReservationDetail({
          hotelId: hotelId as string,
          reservationId: reservationId as string
        })
          .then((response) => {
            if (response.responseCode === 200) {
              if (response.reservationDetail) {
                setPartnerReservationDetail(response);
              }
            }
          })
          .catch((error) => {
            console.log('error', error);
          })
      );
    })();
  }, []);
  const showMobileReservationInputHandler = (flag: boolean) => {
    setShowMobileReservationInput(flag);
  };
  return (
    <div ref={componentRef}>
      <Helmet>
        <title>{`Reservations details | ${hotelName} | Booking.com`}</title>
      </Helmet>
      <header>
        <PartnerHeader
          showMobileReservationInputFlag={showMobileReservationInput}
          showMobileReservationInputHandler={showMobileReservationInputHandler}
        />
      </header>
      <AppInfo showMobileReservationInputFlag={showMobileReservationInput} />
      <main>
        <div
          className={styles.partnerReservationDetailViewContainer}
          data-testid={'partnerReservationDetailViewContainerTestId'}
          id={'partnerReservationDetails'}>
          <Button
            type={'button'}
            className={styles.returnToReservationListButton}
            onClick={() => navigate(`/partner/hotel/reservation/${hotelId}/${hotelName}`)}>
            {t('ReturnToReservationsList')}
          </Button>
          <h1>{t('ReservationDetails')}</h1>
          <div className={styles.partnerReservationDetailViewOuterContainer}>
            <div className={styles.partnerReservationViewGuestDetailContainer}>
              {!checkMobileScreenFlag &&
                partnerReservationDetail &&
                partnerReservationDetail.reservationDetail && (
                  <ReservationDetailCard
                    partnerReservationDetail={partnerReservationDetail.reservationDetail}
                  />
                )}
              {checkMobileScreenFlag &&
                partnerReservationDetail &&
                partnerReservationDetail.reservationDetail && (
                  <MobileReservationDetailCard
                    partnerReservationDetail={partnerReservationDetail.reservationDetail}
                  />
                )}
              {partnerReservationDetail &&
                partnerReservationDetail.reservationDetail &&
                partnerReservationDetail.reservationDetail.roomsDetails.length > 0 &&
                partnerReservationDetail.reservationDetail.roomsDetails.map(
                  (roomDetail: IRoomDetail, i: number) => {
                    const buf = new Uint8Array(1);
                    const keyValue = crypto.getRandomValues(buf);
                    const statusTextColorClassName = classnames(styles.statusTextOk, {
                      [styles.statusTextCancelled]: roomDetail.roomStatus === 'Cancelled'
                    });
                    const roomTextTextDecorationClassName = classnames(
                      styles.roomDetailHeaderText,
                      {
                        [styles.roomTextCanceledStatusTextDecoration]:
                          roomDetail.roomStatus === 'Cancelled'
                      }
                    );
                    return (
                      <Card className={styles.roomDetailContainer} key={Number(keyValue)}>
                        <p className={roomTextTextDecorationClassName}>
                          <strong>{`Room ${i + 1}`}</strong>
                        </p>
                        {roomDetail.guestName !== 'No information available' && (
                          <p>{`Guest name: ${roomDetail.guestName} `}</p>
                        )}
                        {roomDetail.roomStatus !== 'No information available' && (
                          <p>
                            <span>{`Status: `}</span>
                            <span
                              className={
                                statusTextColorClassName
                              }>{`${roomDetail.roomStatus} `}</span>
                          </p>
                        )}
                      </Card>
                    );
                  }
                )}
            </div>
            {partnerReservationDetail && partnerReservationDetail.reservationDetail && (
              <div className={styles.partnerReservationDetailPrintContainer}>
                <p>
                  You are using a temporary service provided by Booking.com. At this time it is not
                  possible to carry out any actions related to this reservation, including viewing
                  credit card payment information. We apologise for this inconvenience.{' '}
                </p>
                <br />
                <p>
                  Additional information related to this reservation, such as arrival time, may have
                  been sent to you by email.
                </p>
                <br />
                <Card className={styles.printContainer}>
                  <Button onClick={handlePrint} type={'button'} className={styles.printButton}>
                    Print this page
                  </Button>
                </Card>
              </div>
            )}
          </div>
        </div>
      </main>
      <footer className={styles.reservationDetailPartnerFooter}>
        <PartnerFooter />
      </footer>
    </div>
  );
};

export default PartnerReservationDetailViewPage;
