import React, { useState, useEffect } from 'react';
import Card from '../UI/card/Card';
import styles from './MultipleProperties.module.css';
import PropertyTable from '../propertyTable/PropertyTable';
import Pagination from '../UI/pagination/Pagination';
import BasicSelect from '../UI/basicSelect/BasicSelect';
import MobilePropertyTable from '../mobilePropertyTable/MobilePropertyTable';
import propertyTableData from '../../data/propertyTableData';
import { userPropertySliceHandler, reversePropertySortFieldFormat } from '../../utils/utils';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import SinglePropertyForm from '../singlePropertyForm/SinglePropertyForm';
interface IHotel {
  hotel_id: string;
  callback_number: string;
  hotelName: string;
}

interface IPartnerPropertyList {
  message: string;
  responseCode: number;
  properties: {
    propertyCount: string | number;
    hotels: Array<IHotel>;
  };
}
interface IMultipleProperties {
  partnerPropertyList: IPartnerPropertyList;
}
const MultipleProperties = ({ partnerPropertyList }: IMultipleProperties) => {
  const { t } = useTranslation();
  const [propertyData, setPropertyData] = useState(partnerPropertyList.properties.hotels);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchSinglePropertyValue, setSearchSinglePropertyValue] = useState('');
  const [searchSinglePropertyValueType, setSearchSinglePropertyValueType] = useState('');
  const changePageSizeHandler = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };
  const getPageStart = (pageLimit: number, pageNr: number) => {
    return pageLimit * pageNr;
  };
  const getPageLabel = (total: number, pageLimit: number, pageNr: number) => {
    const start = Math.max(getPageStart(pageLimit, pageNr), 0);
    const end = Math.min(getPageStart(pageLimit, pageNr + 1), total);

    return `${start + 1} - ${end}`;
  };

  const itemsToShow = Array.from({ length: propertyTableData.length }, (_, i) => `Item ${i + 1}`);

  const intervals = Array.from({ length: Math.ceil(itemsToShow.length / pageSize) }, (_, i) =>
    getPageLabel(itemsToShow.length, pageSize, i)
  );

  const pageNumberChangeHandler = (page: number | string) => {
    setCurrentPage(+page);
  };

  const showIntervals = () => {
    if (+partnerPropertyList.properties.propertyCount > 10) {
      return intervals[currentPage - 1];
    } else {
      return `1 - ${partnerPropertyList.properties.propertyCount}`;
    }
  };

  const userPropertySortTableHandler = (key: string, order: string) => {
    if (key === 'hotel_id') {
      if (reversePropertySortFieldFormat(order)) {
        setPropertyData((prevPropertyData) =>
          [...prevPropertyData].sort((a, b) => +a.hotel_id - +b.hotel_id)
        );
      } else {
        setPropertyData((prevPropertyData) =>
          [...prevPropertyData].sort((a, b) => +b.hotel_id - +a.hotel_id)
        );
      }
    } else if (key === 'hotelName') {
      if (reversePropertySortFieldFormat(order)) {
        setPropertyData((prevPropertyData) =>
          [...prevPropertyData].sort((a, b) => {
            const hotelNameA = a.hotelName.toUpperCase();
            const hotelNameB = b.hotelName.toUpperCase();
            if (hotelNameA < hotelNameB) {
              return -1;
            }
            if (hotelNameA > hotelNameB) {
              return 1;
            }
            return 0;
          })
        );
      } else {
        setPropertyData((prevPropertyData) =>
          [...prevPropertyData].sort((a, b) => {
            const hotel_name_a = a.hotelName.toUpperCase();
            const hotel_name_b = b.hotelName.toUpperCase();
            if (hotel_name_a < hotel_name_b) {
              return 1;
            }
            if (hotel_name_a > hotel_name_b) {
              return -1;
            }
            return 0;
          })
        );
      }
    }
  };

  const filterPropertyDetails = (value: string, keyType: string) => {
    if (value === '') {
      return partnerPropertyList.properties.hotels;
    }
    if (keyType === 'propertyNumber') {
      return propertyData.filter((property) => property.hotel_id === value);
    } else {
      return propertyData.filter((property) => property.hotelName === value);
    }
  };

  const propertyFormHandler = (value: string) => {
    // if(value !== ''){
    setSearchSinglePropertyValue(value);
    if (!isNaN(Number(value))) {
      setSearchSinglePropertyValueType('propertyNumber');
    } else {
      setSearchSinglePropertyValueType('propertyName');
    }
    // }
  };

  useEffect(() => {
    const filteredProperty = filterPropertyDetails(
      searchSinglePropertyValue,
      searchSinglePropertyValueType
    );
    setPropertyData(filteredProperty);
  }, [searchSinglePropertyValue, searchSinglePropertyValueType]);

  return (
    <section
      className={styles.partnerSectionStyle}
      aria-label="view user property list"
      data-testid={'multiplePropertiesSectionTestId'}>
      <Helmet>
        <title>{`Your Properties | Booking.com`}</title>
      </Helmet>
      {/*Commenting below for now .will be removed or added once more details requirements comes  */}
      {/*<Card className={styles.propertyPartnerInfo}>*/}
      {/*  <p className={styles.propertyPartnerInfoText}>*/}
      {/*    During this period while we work to restore full access to our platform, please use this*/}
      {/*    temporary interface to view your confirmed reservations.*/}
      {/*  </p>*/}
      {/*</Card>*/}
      <div className={styles.yourPropertyContainer}>
        <h1>
          <strong>{t('YourProperties')}</strong>
        </h1>
        <p>{t('PleaseSelectRequiredPropertyInfo')}</p>
      </div>
      <div className={styles.singlePropertyFormContainer}>
        <SinglePropertyForm singlePropertyFormValueCallbackHandler={propertyFormHandler} />
      </div>

      <MobilePropertyTable
        mobileCurrentPageNumber={currentPage}
        mobilePageSizeNumber={pageSize}
        totalHotelCount={+partnerPropertyList.properties.propertyCount}
        userHotelDetails={userPropertySliceHandler(currentPage, pageSize, propertyData)}
        setMobilePageNumberHandler={pageNumberChangeHandler}
      />
      <Card className={styles.propertyListContainer}>
        <PropertyTable
          userHotelDetails={userPropertySliceHandler(currentPage, pageSize, propertyData)}
          propertySortTableHandler={userPropertySortTableHandler}
        />
        {propertyData.length > 10 && (
          <div className={styles.propertyListPaginationContainer}>
            <Pagination
              onPageChange={(page) => {
                setCurrentPage(Number(page));
              }}
              totalCount={+partnerPropertyList.properties.propertyCount}
              currentPage={currentPage}
              pageSize={pageSize}
              className={styles.paginationBarContainer}
            />
            <div className={styles.totalNumberResultsContainer}>
              {showIntervals()} {t('Of')} {+partnerPropertyList.properties.propertyCount}{' '}
              {t('Results')}
            </div>
            <div className={styles.totalNumberResultsContainer}>
              <BasicSelect pageSize={pageSize} changePageSizeHandler={changePageSizeHandler} />
            </div>
          </div>
        )}
      </Card>
    </section>
  );
};

export default MultipleProperties;
