import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationENGB from '../../assets/locales/en-GB/translation.json';
import translationENUS from '../../assets/locales/en-US/translation.json';
import translationEN from '../../assets/locales/en/translation.json';
import translationFR from '../../assets/locales/fr/translation.json';
import translationIT from '../../assets/locales/it/translation.json';
import tranlationES from '../../assets/locales/es/translation.json';
import translationDE from '../../assets/locales/de/translation.json';
import translationRU from '../../assets/locales/ru/translation.json';
import translationXB from '../../assets/locales/xb/translation.json';
import translationPL from '../../assets/locales/pl/translation.json';
import translationHR from '../../assets/locales/hr/translation.json';
const fallbackLng = ['en-GB'];

// it - Italian
// es - Spanish
// fr - French
// de - German (Deutsch)
// ru - Russian
// xb - Brazilian Portuguese
// pl - Polish
// hr - Croation **
// cz - Czech Republic

const availableLanguages = ['en', 'en-GB', 'en-US', 'it', 'es', 'fr', 'de', 'ru', 'xb', 'pl', 'hr'];

const resources = {
  en: {
    translation: translationEN
  },
  'en-GB': {
    translation: translationENGB
  },
  'en-US': {
    translation: translationENUS
  },
  fr: {
    translation: translationFR
  },
  it: {
    translation: translationIT
  },
  es: {
    translation: tranlationES
  },
  de: {
    translation: translationDE
  },
  ru: {
    translation: translationRU
  },
  xb: {
    translation: translationXB
  },
  pl: {
    translation: translationPL
  },
  hr: {
    translation: translationHR
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    debug: false, //Detects and caches a cookie from the language provided
    supportedLngs: availableLanguages,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
