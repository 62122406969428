import React from 'react';
import { Pagination } from '@bookingcom/bui-react';
import { usePagination } from '../../../hooks/usePagination';
import styles from './SimpleBuiPagination.module.css';

interface ISimpleBuiPagination {
  onPageChange: (pageNumber: number | string) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;

  // className: string;
}

const SimpleBuiPagination = (props: ISimpleBuiPagination) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  const lastPage = paginationRange && paginationRange[paginationRange.length - 1];
  return (
    <Pagination
      currentPage={currentPage}
      previousLabel="Back"
      ariaPreviousLabel="Previous Page"
      ariaNextLabel="Next Page"
      nextLabel="Next"
      pageLabelPrefix="Page"
      isLastPage={currentPage === lastPage}
      onPageChange={({ page }) => {
        onPageChange(page);
      }}
      className={styles.simpleBuiPaginationContainer}
    />
  );
};

export default SimpleBuiPagination;
