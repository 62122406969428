import styles from './Navbar.module.css';
import React from 'react';
import bookingLogo from '../../assets/images/BookingLogo.svg';

const Navbar = () => {
  return (
    <img
      data-testid="bookingLogoId"
      alt="Booking.com"
      className={styles.bookingLogo}
      src={bookingLogo}
    />
  );
};

export default Navbar;
