import React, { useState } from 'react';
import styles from './SelectedLanguage.module.css';
import { ILanguage } from '../../interfaces';
import { useTranslation } from 'react-i18next';

interface IFlagSelectProps {
  language: ILanguage;
  changeLanguageModal: (flag: boolean) => void;
  hoverStyle: {
    onHoverLanguage: string;
    backgroundColorLanguage: string;
  };
  selectLanguageClassName?: string;
}
const SelectedLanguage = ({
  language,
  changeLanguageModal,
  hoverStyle,
  selectLanguageClassName
}: IFlagSelectProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const lngSelectorLinkTitle = t('select_language');
  return (
    <div
      className={`${styles.lSelector} ${selectLanguageClassName}`}
      data-testid="selectedLanguageTestId">
      <a
        tabIndex={1}
        href="#"
        title={lngSelectorLinkTitle}
        onClick={(e) => {
          e.preventDefault();
          changeLanguageModal(true);
        }}
        aria-label={`Language: ${language.name}`}>
        <div
          style={{
            backgroundColor: isHovering
              ? hoverStyle.onHoverLanguage
              : hoverStyle.backgroundColorLanguage
          }}
          className={styles.selectedFlag}
          data-testid="selectedLanguageModalTestId"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={(e) => {
            e.preventDefault();
            changeLanguageModal(true);
          }}>
          <img
            data-testid="selectedLanguageFlag"
            className={styles.spui_avatar__image}
            src={language.flag}
            alt={`Selected language ${language.name}`}
          />
        </div>
      </a>
    </div>
  );
};

export default SelectedLanguage;
