import React from 'react';
import styles from './Button.module.css';

interface IButton {
  children?: React.ReactNode;
  type: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler;
  className?: string;
  disabled?: boolean;
}
const Button = ({ children, type, onClick, className, disabled }: IButton) => {
  return (
    <button
      data-testid={'buttonTestId'}
      className={`${styles.buttonContainer} ${className}`}
      type={type}
      disabled={disabled}
      onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
