import gb_flag from '../assets/images/flags/circular/gb.png';
import br_flag from '../assets/images/flags/circular/br.png';
import de_flag from '../assets/images/flags/circular/de.png';
import es_flag from '../assets/images/flags/circular/es.png';
import fr_flag from '../assets/images/flags/circular/fr.png';
import it_flag from '../assets/images/flags/circular/it.png';
import pl_flag from '../assets/images/flags/circular/pl.png';
import ru_flag from '../assets/images/flags/circular/ru.png';
import us_flag from '../assets/images/flags/circular/us.png';
import hr_flag from '../assets/images/flags/circular/hr.png';

const availableLanguages = [
  { id: 1, name: 'English (UK)', code: 'en-GB', flag: gb_flag },
  { id: 2, name: 'English (US)', code: 'en-US', flag: us_flag },
  { id: 3, name: 'Deutsch', code: 'de', flag: de_flag },
  { id: 4, name: 'Français', code: 'fr', flag: fr_flag },
  { id: 5, name: 'Español', code: 'es', flag: es_flag },
  { id: 6, name: 'Italiano', code: 'it', flag: it_flag },
  { id: 8, name: 'Polski', code: 'pl', flag: pl_flag },
  { id: 9, name: 'Русский', code: 'ru', flag: ru_flag },
  { id: 10, name: 'Hrvatski', code: 'hr', flag: hr_flag },
  { id: 11, name: 'Português (BR)', code: 'xb', flag: br_flag }
];

export default availableLanguages;
