import styles from './MobileSingleReservationForm.module.css';
import { Formik, FormikErrors } from 'formik';
import Button from '../UI/button/Button';
import Input from '../UI/input/Input';
import { useTranslation } from 'react-i18next';
interface IOnSubmitInput {
  setSubmitting: (flag: boolean) => void;
}
interface IReservationIdFormValues {
  reservationId: string;
}
const MobileSingleReservationForm = () => {
  const { t } = useTranslation();
  const reservationIdValidateHandler = (values: IReservationIdFormValues) => {
    const errors: FormikErrors<IReservationIdFormValues> = {};
    if (
      values.reservationId !== '' &&
      !/^(?=(?:\D*\d){10}\D*$)[\d\s.]+$/i.test(values.reservationId.toString())
    ) {
      errors.reservationId = 'isInvalid';
    }
    return errors;
  };
  const reservationIdSubmitHandler = (
    values: IReservationIdFormValues,
    { setSubmitting }: IOnSubmitInput
  ) => {
    console.log('values====>', values);
    setSubmitting(false);
  };
  return (
    <>
      <Formik
        initialValues={{
          reservationId: ''
        }}
        validate={reservationIdValidateHandler}
        onSubmit={reservationIdSubmitHandler}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className={styles.mobileReservationIdForm}>
            <Input
              className={styles.mobileReservationIdInput}
              inputVectorImgClassName={styles.mobileErrorVectorImg}
              data-testid={'mobileReservationIdFormTestId'}
              label={`${t('SearchForReservations')}`}
              id="reservationId"
              name="reservationId"
              type={'text'}
              placeholder={' '}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.reservationId}
              errorType={errors.reservationId}
              touched={touched.reservationId}
            />
            <Button
              className={styles.mobileReservationSearchButton}
              type={'submit'}
              disabled={isSubmitting}>
              {t('Search')}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default MobileSingleReservationForm;
