import React, { MouseEventHandler, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Cookie.module.css';

interface Props {
  toggleCookie: (status: boolean) => void;
  togglePrivacySettings: (status: boolean) => void;
}
const Cookie = ({ toggleCookie, togglePrivacySettings }: Props) => {
  const handleCookie: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    toggleCookie(false);
  };

  const handlePrivacySettings: MouseEventHandler = (e) => {
    e.preventDefault();
    togglePrivacySettings(true);
    toggleCookie(false);
  };
  const { t } = useTranslation();

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    buttonRef.current?.focus();
  }, []);
  return (
    <div
      role="alertdialog"
      aria-labelledby="cookie_header"
      aria-describedby="cookie_desc"
      className={styles.cookieBanner}>
      <div className={styles.cookie}>
        <div className={styles.managePreference}>
          <h2 id="cookie_header" className={styles.manageCookiePreferences}>
            {t('manage_cookie_header')}
          </h2>
          <p id="cookie_desc" className={styles.mangeCookieContent}>
            {t('manage_cookie_content')}
          </p>
        </div>

        <div className={styles.manageSettings}>
          <button ref={buttonRef} className={styles.acceptSettings} onClick={handleCookie}>
            {t('manage_cookie_accept')}
          </button>

          <button
            onClick={handlePrivacySettings}
            className={styles.manageCookieSettings}
            data-testid="openPrivacySettingsbtnTestId">
            <p>{t('manage_cookie_settings_link')}</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cookie;
