import React from 'react';
import styles from './PartnerLogin.module.css';
import { Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import FormInput from '../UI/formInput/FormInput';
import { Alert } from '@bookingcom/bui-react';

interface IShowReservationElements {
  showEnableAdvanceFunctionality: boolean;
  showCustomerReservation: boolean;
  showReservationAssistance: boolean;
  showSisterBrandLink: boolean;
  showReservationAssistanceNumber: string;
}

interface IReservationInput {
  showAlert: boolean;
  alertType: 'success' | 'error';
  hideAlertHandler: () => void;
  reservationElementsConfig: IShowReservationElements;
  getBookingReservationDetailsHandler: (username: string) => void;
}

interface IOnSubmitInput {
  setSubmitting: (flag: boolean) => void;
}

interface FormValues {
  userName: string;
}

const PartnerLogin = ({
  showAlert,
  alertType,
  hideAlertHandler,
  reservationElementsConfig,
  getBookingReservationDetailsHandler
}: IReservationInput) => {
  const { t } = useTranslation();
  let partnerHeadingText = t('partnerHeading');
  let partnerImportantText = t('right_important_partner_label');
  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    if (!values.userName) {
      errors.userName = 'isEmpty';
    }
    return errors;
  };

  if (
    !reservationElementsConfig.showEnableAdvanceFunctionality ||
    (reservationElementsConfig.showEnableAdvanceFunctionality &&
      !reservationElementsConfig.showCustomerReservation)
  ) {
    partnerHeadingText = t('partnerUserInactivityHeading');
    partnerImportantText = t('partnerInactivityRightImportantPartnerLabel');
  }

  const onSubmit = async (values: FormValues, { setSubmitting }: IOnSubmitInput) => {
    getBookingReservationDetailsHandler(values.userName);
    setSubmitting(false);
  };

  return (
    <div className={styles.reservationContainer}>
      <section aria-label="How to access your reservation">
        <div className={styles.reservationInputContainer} data-testid="reservationInputDiv">
          <div className={styles.reservationHeading}>
            <h1 id="accessReservation" className={styles.reservationInputHeadingText}>
              <strong className={styles.reservationInputHeading}>
                {t('partner_input_heading')}
              </strong>
            </h1>
            <p>{partnerHeadingText}</p>
          </div>
          <p className={styles.reservationInputFormImportantText}>{partnerImportantText}</p>

          {showAlert && (
            <div>
              <Alert
                title={t(`partnerAlert.${alertType}.title`)}
                text={t(`partnerAlert.${alertType}.content`)}
                variant={alertType}
              />
              <button className={styles.backButton} onClick={hideAlertHandler}>
                Back
              </button>
            </div>
          )}
          {!showAlert && reservationElementsConfig.showCustomerReservation && (
            <Formik initialValues={{ userName: '' }} validate={validate} onSubmit={onSubmit}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form
                  autoComplete="off"
                  className={styles.reservationInputForm}
                  onSubmit={handleSubmit}>
                  <FormInput
                    label={t('partnerInputContainer_label')}
                    id="userName"
                    name="userName"
                    placeholder={''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userName}
                    errorType={errors.userName}
                    touched={touched.userName}
                    type={'text'}
                  />
                  <button type="submit" disabled={isSubmitting}>
                    {t('partnerInputContainer_button')}
                  </button>
                </form>
              )}
            </Formik>
          )}
        </div>
      </section>
      {reservationElementsConfig.showReservationAssistance && (
        <div className={styles.bookingDottedLine} />
      )}
      {reservationElementsConfig.showReservationAssistance && (
        <section aria-label="Get help by phone">
          <div className={styles.bookingCustomerService} data-testid="bookingCustomerServiceDiv">
            <div className={styles.bookingAssistance}>
              <h2 className={styles.reservationInputHeadingText}>
                <strong className={styles.reservationInputHeading}>
                  {t('reservation_booking_assistance_heading')}
                </strong>
              </h2>
              <p>
                {t('reservationBookingAssistance_1')}{' '}
                <strong>{t('reservationBookingAssistance_2')}</strong>{' '}
                {t('reservationBookingAssistance_3')}{' '}
                <strong>{t('reservationBookingAssistance_4')}</strong>
                {t('reservationBookingAssistance_5')}
              </p>
            </div>
            <div className={styles.bookingCustomerNumber}>
              <p>{reservationElementsConfig.showReservationAssistanceNumber}</p>
            </div>
            <div className={styles.bookingLanguageSupport}>
              <p>{t('partner_bookingLanguageSupport')}</p>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};
export default PartnerLogin;
