import { Route, Routes, useLocation } from 'react-router-dom';
import styles from './App.module.css';
import HomePage from './pages/homePage/HomePage';
import PartnerLandingPage from './pages/partnerLandingPage/PartnerLandingPage';
import MagicLink from './pages/magicLink/MagicLink';
import classnames from 'classnames';
import Spinner from './components/UI/spinner/Spinner';
import PartnerReservationViewPage from './pages/partnerReservationViewPage/PartnerReservationViewPage';
import PartnerReservationDetailViewPage from './pages/partnerReservationDetailViewPage/PartnerReservationDetailViewPage';
import PartnerInActivityPage from './pages/partnerInActivityPage/PartnerInActivityPage';
function App() {
  const location = useLocation();
  const appContainerClassNames = classnames(styles.container, {
    [styles.partnerContainer]: location.pathname !== '/'
  });
  return (
    <>
      <div className={appContainerClassNames}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/partner/property" element={<PartnerLandingPage />} />
          <Route
            path="/partner/hotel/reservation/:hotelId/:hotelName"
            element={<PartnerReservationViewPage />}
          />
          <Route
            path="/partner/reservationDetail/:hotelId/:hotelName/:reservationId"
            element={<PartnerReservationDetailViewPage />}
          />
          <Route path={'/partner/inActivity'} element={<PartnerInActivityPage />} />
          <Route path="/magic-link" element={<MagicLink />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
        <Spinner />
        {/* Note:cookie and privacy will be uncommented once the requirements comes*/}
        {/* {showCookie && (*/}
        {/*  <Cookie toggleCookie={setShowCookie} togglePrivacySettings={setOpenPrivacySettings} />*/}
        {/*)}*/}
      </div>
    </>
  );
}

export default App;
