import React, { useState } from 'react';
import {
  getDefaultLanguageHandler,
  Languages,
  LanguageSelector,
  Navbar,
  PrivacySettings,
  SelectedLanguage,
  Cookie
} from 'abu-common-components';
import styles from '../../App.module.css';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { i18n } = useTranslation();
  const defaultLanguage = getDefaultLanguageHandler(i18n, Languages);
  const [language, setLanguage] = useState(defaultLanguage);
  const [openLanguageModal, setOpenLanguageModal] = useState(false);
  const [openPrivacySettings, setOpenPrivacySettings] = useState(false);
  const [showCookie, setShowCookie] = useState(false);
  return (
    <>
      {openLanguageModal && (
        <div role="dialog">
          <LanguageSelector
            availableLanguages={Languages}
            changeLanguage={setLanguage}
            language={language}
            toggleLanguageModal={setOpenLanguageModal}
          />
        </div>
      )}
      {openPrivacySettings && <PrivacySettings togglePrivacySetting={setOpenPrivacySettings} />}
      <header>
        <a tabIndex={1} href="#accessReservation" className={styles.skipToMainContentLink}>
          Skip to main content
        </a>
        <div className={styles.navBar}>
          <div className={styles.headerLogo}>
            {' '}
            <Navbar />{' '}
          </div>
          <div className={styles.selectedLanguageContainer}>
            <SelectedLanguage
              language={language}
              changeLanguageModal={setOpenLanguageModal}
              hoverStyle={{
                onHoverLanguage: 'rgba(0,0,0,0.06)',
                backgroundColorLanguage: '#FFFFFF'
              }}
            />
          </div>
        </div>
      </header>
      {showCookie && (
        <Cookie toggleCookie={setShowCookie} togglePrivacySettings={setOpenPrivacySettings} />
      )}
    </>
  );
};

export default Header;
