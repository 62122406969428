import React, { useEffect } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_USERPOOL_REGION,
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_CLIENT_ID,
    mandatorySignIn: true
  }
});

const MagicLink = () => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const b64decode = (str: string): string => Buffer.from(str, 'base64').toString('binary');
  const decodedEmail = b64decode(queryParams.get('username')!);
  const queryToken = queryParams.get('token');
  async function currentSession() {
    try {
      const user = await Auth.currentSession();
      if (user) {
        // await Auth.signOut(); Need tp be tested
        return false;
      }
    } catch (err) {
      return false;
    }
    return false;
  }

  async function signIn(email: string) {
    try {
      return await Auth.signIn(email);
    } catch (error) {
      throw new Error('Sign in failed');
    }
  }

  async function answerCustomChallenge(cognitoUser: string, token: string) {
    try {
      return await Auth.sendCustomChallengeAnswer(cognitoUser, token);
    } catch (error) {
      alert(t(`The_sign_in_link_has_expired`));
      throw new Error('The token is invalid.');
    }
  }

  useEffect(() => {
    (async () => {
      if (queryToken) {
        try {
          const getCurrentSession = await currentSession();
          if (!getCurrentSession) {
            const email = decodeURIComponent(decodedEmail);
            const cognitoUser = await signIn(email);
            if (cognitoUser) {
              const answerCustom = await answerCustomChallenge(cognitoUser, queryToken);
              answerCustom && navigate('/partner/property', { replace: true });
            }
          } else {
            navigate('/partner/property', { replace: true });
          }
        } catch (error) {
          navigate('/', { replace: true });
        }
      }
    })();
  }, [decodedEmail, queryToken]);

  return <div>...Redirecting</div>;
};

export default MagicLink;
