import React from 'react';
import Button from '../UI/button/Button';
import styles from './HotelReservationInputs.module.css';
import { Formik } from 'formik';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import DatePickerField from '../UI/datePickerField/DatePickerField';
import Input from '../UI/input/Input';
import {
  IOnSubmitInput,
  IPartnerReservationFormValues,
  IPartnerReservationApiFormValues
} from '../../interfaces';
import { paginationConstants } from '../../utils/constant';
import { getDateFormat, getDateTypeTobeSearchedForApi } from '../../utils/utils';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IHotelReservationInputs {
  postPartnerReservationListHandler: (values: IPartnerReservationApiFormValues) => void;
}
const HotelReservationInputs = ({ postPartnerReservationListHandler }: IHotelReservationInputs) => {
  const { t } = useTranslation();
  const { hotelId } = useParams();
  const onSubmit = (values: IPartnerReservationFormValues, { setSubmitting }: IOnSubmitInput) => {
    const partnerReservationApiFormValues = {
      reservationId: '',
      hotelId: hotelId ? hotelId.toString() : '',
      dateUntil: values.dateUntil ? getDateFormat(values.dateUntil as Date) : '',
      dateFrom: values.dateFrom ? getDateFormat(values.dateFrom as Date) : '',
      dateTypeToBeSearched: getDateTypeTobeSearchedForApi(values.dateOfSelector),
      bookerName: '',
      pageNumber: paginationConstants.pageNumber,
      sortField: paginationConstants.sortField,
      sortOrder: paginationConstants.sortOrder,
      pageLimit: paginationConstants.pageLimit
    };
    if (/^(?=(?:\D*\d){10}\D*$)[\d\s.]+$/i.test(values.reservationIdOrName.toString())) {
      partnerReservationApiFormValues.reservationId = values.reservationIdOrName;
    } else {
      partnerReservationApiFormValues.bookerName = values.reservationIdOrName;
    }
    postPartnerReservationListHandler(partnerReservationApiFormValues);
    setSubmitting(false);
  };
  const options = ['Check-in', 'Check-out'];

  return (
    <>
      <Formik
        initialValues={{
          dateOfSelector: 'Check-in',
          dateFrom: new Date(),
          dateUntil: new Date(),
          reservationIdOrName: ''
        }}
        onSubmit={onSubmit}>
        {({ values, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
          <form onSubmit={handleSubmit} className={styles.hotelReservationInputsContainer}>
            <Box data-testid="basicSelectTestId">
              <FormControl className={styles.selectDateOuterContainer} style={{ width: '100%' }}>
                <label
                  htmlFor={'dateOfSelector'}
                  className={styles.selectDateLabelText}
                  id="dateOfSelector">
                  {t('Date-Of')}
                </label>
                <Select
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#949494'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#0071c2'
                    }
                  }}
                  className={styles.selectDateContainer}
                  labelId="dateOfSelector"
                  name={'dateOfSelector'}
                  id="dateOfSelector"
                  data-testid="dateOfSelectorTestId"
                  value={values.dateOfSelector}
                  defaultValue={t(`${options[0]}`)}
                  onChange={(e) => {
                    setFieldValue('dateOfSelector', e.target.value);
                  }}>
                  {options.map((x) => (
                    <MenuItem key={x} value={x}>
                      {t(`${x}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <div className={styles.datePickerFieldContainer}>
              <DatePickerField
                dateFieldInputClassName={styles.dateFieldInputDiv}
                id={'dateFrom'}
                label={t('From')}
                name={'dateFrom'}
                onChange={setFieldValue}
                value={values.dateFrom}
                maxDate={values.dateUntil && new Date(values.dateUntil)}
              />
              <DatePickerField
                dateFieldInputClassName={styles.dateFieldInputDiv}
                id={'dateUntil'}
                label={t('Until')}
                name={'dateUntil'}
                onChange={setFieldValue}
                value={values.dateUntil}
                minDate={values.dateFrom && new Date(values.dateFrom)}
              />
            </div>
            <Input
              inputClassName={styles.searchInput}
              data-testid={'SearchId'}
              label={`${t('Search')}`}
              id="reservationIdOrName"
              name="reservationIdOrName"
              type={'text'}
              placeholder={`${t('ReservationIdOrTravelerName')}`}
              onChange={handleChange}
              onBlur={handleChange}
              value={values.reservationIdOrName}
            />
            <Button
              className={styles.searchReservationButton}
              type={'submit'}
              disabled={isSubmitting}>
              {t('SearchForReservations')}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default HotelReservationInputs;
